<template>
  <div class="container-lg">
    <spacer :y="6"/>
    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-pen-to-square me-3"/>基本情報{{ urlParam === 'regist' ? '登録' : '編集'}}</div>
      <div class="d-flex align-items-center" v-if="!flag.isLoading.get">
        <!-- 店舗選択 -->
        <div class="w-150px me-2" v-if="isSuperUser(user)">
          <select
            class="form-control form-control-sm"
            :class="!shopId ? 'bg-red' : ''"
            v-model="shopId">
            <option :value="0">店舗未選択</option>
            <option
              v-for="s in shops" :key="s.id"
              :value="s.id">{{ s.name }}</option>
          </select>
        </div>
        <!-- ステータス選択 -->
        <div class="w-150px">
          <FormParts
            :type="'select'"
            :options="obj2Array(carLabelObj('flag'))"
            :name="'flag'"
            :initValue="values.flag" />
        </div>
        <button
          type="button"
          class="btn btn-primary btn-sm ms-2"
          :disabled="!readySubmit"
          @click="regist">登録</button>
      </div>
    </div>

    <spacer :y="2"/>

    <!-- form -->
    <Loader :flag="flag.isLoading.get">
      <div v-if="urlParam !== 'regist' && salesCar?.flag === 1">
        <a
          :href="`${frontBaseUrl}/sales-car/${salesCar.slug}/`"
          target="_brank"
          class="text-decoration-underline">{{ frontBaseUrl}}/sales-car/{{ salesCar.slug }}/</a>
      </div>
      <div class="d-flex fs-xs fc-red justify-content-end">「*」は必須項目です。</div>
      <div class="d-flex align-items-start bdr" v-if="!flag.isLoading.get">
        <ul
          v-for="(value, key) in formItems"
          :key="key"
          :class="s.forms">
          <li
            v-for="(item, i) in value"
            :key="i"
            class="d-flex">
            <div class="fs-xs bg-gray w-150px d-flex align-items-center flex-shrink-0">{{ item.label }}<span v-if="item.required" class="fc-red">*</span></div>
            <div :class="item.width === 'full' ? 'flex-1' : ''">

              <!-- グレード -->
              <div
                v-if="item.name === 'grade'">
                <FormParts
                  :type="'text'"
                  :name="'grade'"
                  :initValue="values.grade"/>
                <spacer :y="0.5"/>
                <div class="fs-xs">付加情報</div>
                <FormParts
                  :type="'textarea'"
                  :name="'grade_comment'"
                  :initValue="values.grade_comment"/>
              </div>

              <!-- カラー -->
              <div
                v-if="item.name === 'color'">
                <div class="d-flex">
                  <FormParts
                    class="flex-1"
                    :type="'select'"
                    :name="'color_type'"
                    :initValue="values.color_type"
                    :required="!values.color_type"
                    :options="options('color_type')"/>
                  <spacer :x="0.5"/>
                  <FormParts
                    class="flex-1"
                    :type="'select'"
                    :name="'color_detail'"
                    :initValue="values.color_detail"
                    :disabled="!values.color_type"
                    :required="values.color_type && !values.color_detail"
                    :options="options('color_detail')"/>
                </div>
                <spacer :y="0.5"/>
                <FormParts
                  :type="'text'"
                  :name="'color_name'"
                  :initValue="values.color_name"/>
              </div>

              <!-- 車検 -->
              <div
                v-if="item.name === 'inspection'">
                <FormParts
                  class="flex-1"
                  :type="'select'"
                  :name="'inspection_type'"
                  :initValue="values.inspection_type"
                  :required="!values.inspection_type"
                  :options="options('inspection_type')"/>
                <spacer :y="0.5"/>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">次回車検年</div>
                    <FormParts
                      :type="'select'"
                      :name="'inspection_year'"
                      :initValue="values.inspection_year"
                      :disabled="values.inspection_type !== 1"
                      :required="values.inspection_type === 1 && !values.inspection_year"
                      :options="options('inspection_year')"/>
                  </div>
                  <spacer :x="0.5"/>
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">次回車検月</div>
                    <FormParts
                      :type="'select'"
                      :name="'inspection_month'"
                      :initValue="values.inspection_month"
                      :disabled="values.inspection_type !== 1"
                      :required="values.inspection_type === 1 && !values.inspection_month"
                      :options="options('inspection_month')"/>
                  </div>
                </div>
              </div>

              <!-- 走行距離 -->
              <div
                v-if="item.name === 'mileage'">
                <FormParts
                  :type="'select'"
                  :name="'mileage_type'"
                  :options="options('mileage_type')"
                  :required="!values.mileage_type"
                  :initValue="values.mileage_type"/>
                <spacer :y="0.5"/>
                <!-- 値によってtextboxだしわけ -->
                <div v-if="values.mileage_type !== 4">
                  <div class="fs-xs fc-gray">走行距離</div>
                  <FormParts
                    class="flex-1"
                    :type="'number'"
                    :name="'mileage'"
                    :initValue="values.mileage"
                    :disabled="values.mileage_type !== 1"
                    :required="values.mileage_type === 1 && !values.mileage && values.mileage !== 0"/>
                </div>
                <div
                  v-if="values.mileage_type === 4"
                  class="d-flex">
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">交換前走行距離</div>
                    <FormParts
                      :type="'number'"
                      :name="'mileage_before'"
                      :initValue="values.mileage_before"
                      :required="!values.mileage_before"/>
                  </div>
                  <spacer :x="0.5"/>
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">交換後走行距離</div>
                    <FormParts
                      :type="'number'"
                      :name="'mileage_after'"
                      :initValue="values.mileage_after"
                      :required="!values.mileage_after"/>
                  </div>
                </div>
              </div>

              <!-- 価格 -->
              <div
                v-if="item.name === 'price'">
                <FormParts
                  class="flex-1"
                  :type="'checkbox'"
                  :name="'price_ask'"
                  :initValue="values.price_ask"
                  :label="'応相談'"/>
                <spacer :y="0.5"/>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">総額</div>
                    <FormParts
                      :type="'number'"
                      :name="'price_total'"
                      :initValue="values.price_total"
                      :disabled="values.price_ask"
                      :required="!values.price_ask && !values.price_total"
                      :suffix="'万円'"/>
                  </div>
                  <spacer :x="0.5"/>
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">本体価格</div>
                    <FormParts
                      :type="'number'"
                      :name="'price_body'"
                      :initValue="values.price_body"
                      :disabled="values.price_ask"
                      :suffix="'万円'"/>
                  </div>
                </div>
              </div>

              <!-- 法廷整備 -->
              <div
                v-if="item.name === 'maintenance'">
                <FormParts
                  class="flex-1"
                  :type="'checkbox'"
                  :name="'maintenance'"
                  :initValue="values.maintenance"
                  :label="'法廷整備あり'"/>
                <spacer :y="0.5"/>
                <div class="fs-xs fc-gray">コメント</div>
                <FormParts
                  :type="'textarea'"
                  :name="'maintenance_comment'"
                  :initValue="values.maintenance_comment"/>
              </div>

              <!-- 保証 -->
              <div
                v-if="item.name === 'warranty'">
                <FormParts
                  :type="'select'"
                  :name="'warranty'"
                  :initValue="values.warranty"
                  :required="values.warranty === null"
                  :options="options('warranty')"/>
                <spacer :y="0.5"/>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">保証期間</div>
                    <FormParts
                      :type="'number'"
                      :name="'warranty_limit'"
                      :initValue="values.warranty_limit"
                      :disabled="values.warranty !== 1"
                      :required="values.warranty === 1 && !values.warranty_limit"/>
                  </div>
                  <spacer :x="0.5"/>
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">保証距離</div>
                    <FormParts
                      :type="'number'"
                      :name="'warranty_distance'"
                      :initValue="values.warranty_distance"
                      :disabled="!(values.warranty === 1 || values.warranty === 2)"
                      :required="(values.warranty === 1 || values.warranty === 2) && !values.warranty_distance"/>
                  </div>
                </div>
                <spacer :y="0.5"/>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">保証期間年</div>
                    <FormParts
                      :type="'select'"
                      :name="'warranty_year'"
                      :options="options('warranty_year')"
                      :initValue="values.warranty_year"
                      :disabled="values.warranty !== 2"
                      :required="values.warranty === 2 && !values.warranty_year"/>
                  </div>
                  <spacer :x="0.5"/>
                  <div class="flex-1">
                    <div class="fs-xs fc-gray">保証期間月</div>
                    <FormParts
                      :type="'select'"
                      :name="'warranty_month'"
                      :options="options('warranty_month')"
                      :initValue="values.warranty_month"
                      :disabled="values.warranty !== 2"
                      :required="values.warranty === 2 && !values.warranty_month"/>
                  </div>
                </div>
              </div>

              <!-- そのほか -->
              <div v-else>
                <FormParts
                  :type="item.type"
                  :options="options(item.name)"
                  :label="item.checkLabel"
                  :name="item.name"
                  :required="item.required && !values[item.name]"
                  :suffix="item.suffix"
                  :initValue="values[item.name]" />
                  <div v-if="['brand_id', 'vehicle_id'].includes(item.name)">
                    <div
                      type="button"
                      class="fs-xs text-decoration-underline mt-2 line-height-1 fc-blue"
                      @click="openRegistModal(item.name)">{{ item.label }}新規登録</div>
                  </div>
              </div>
            </div>
          </li>
        </ul>
      </div>


      <spacer :y="4"/>

      <!-- 画像 -->
      <div v-if="!flag.isLoading.get">
        <div class="fw-bold fs-lg"><i class="fa-regular fa-image me-3"/>車両画像</div>
        <spacer :y="2"/>
        <div class="w-100">
          <ImageForm
            :initMedias="images"
            :count="6"
            @changeFlag="changeImageFlag"
            @changeData="updateImages" />
        </div>
      </div>

      <spacer :y="4"/>

      <!-- オプション -->
      <div v-if="!flag.isLoading.get">
        <div class="fw-bold fs-lg"><i class="fa-regular fa-gear me-3"/>オプション</div>
        <spacer :y="2"/>
        <OptionForm
          :initValue="values.options"/>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  FormParts,
  ImageForm,
  OptionForm,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'sales-car',
  mixins: [cf],
  components: {
    Spacer,
    FormParts,
    ImageForm,
    OptionForm,
    Loader,
  },
  data() {
    return {
      frontBaseUrl: process.env.VUE_APP_FRONT_BASE_URL,
      flag: {
        isLoading: {
          get: true,
        },
        beforeUploadImages: false,
        isSold: false,
        isFirst: true,
      },
      salesCar: null,
      shops: [],
      shopId: 0,
      brands: [],
      vehicles: [],
      images: [],
      formItems: {
        left: [
          {
            name: 'brand_id',
            label: 'ブランド',
            type: 'customedSelect',
            required: true,
          },
          {
            name: 'vehicle_id',
            label: '車種',
            type: 'customedSelect',
            required: true,
          },
          {
            name: 'model',
            label: 'モデル',
            type: 'text',
            width: 'full',
            required: true,
          },
          {
            name: 'identifier',
            label: '型式',
            type: 'text',
            width: 'full',
            required: true,
          },
          {
            name: 'status_type',
            label: '状態',
            type: 'select',
            options: 'master',
            required: true,
          },
          {
            name: 'shape_type',
            label: '形状',
            type: 'select',
            options: 'master',
            required: true,
          },
          {
            name: 'grade',
            label: 'グレード',
            width: 'full',
          },
          {
            name: 'mission',
            label: 'ミッション',
            type: 'select',
            options: 'master',
            required: true,
          },
          {
            name: 'displacement',
            label: '排気量',
            type: 'number',
            required: true,
            suffix: 'cc',
          },
          {
            name: 'engine',
            label: 'エンジン',
            type: 'select',
            options: 'master',
            required: true,
          },
          {
            name: 'price',
            label: '価格',
            width: 'full',
            required: true,
          },
          {
            name: 'maintenance',
            label: '法廷整備',
            width: 'full',
          },
        ],
        right: [
          {
            name: 'door_num',
            label: 'ドア数',
            type: 'select',
            required: true,
          },
          {
            name: 'capacity',
            label: '乗車定員',
            type: 'select',
            options: 'master',
            required: true,
          },
          {
            name: 'regist_year',
            label: '年式（初度登録）',
            type: 'select',
            required: true,
          },
          {
            name: 'regist_month',
            label: '登録月',
            type: 'select',
            required: true,
          },
          {
            name: 'color',
            label: 'カラー',
            width: 'full',
            required: true,
          },
          {
            name: 'size_body',
            label: '車体サイズ',
            type: 'text',
            width: 'full',
          },
          {
            name: 'size_luggage',
            label: '荷台サイズ',
            type: 'text',
            width: 'full',
          },
          {
            name: 'body_num',
            label: '車体番号',
            type: 'text',
            width: 'full',
            required: true,
          },
          {
            name: 'mileage',
            label: '走行距離',
            width: 'full',
            required: true,
          },
          {
            name: 'inspection',
            label: '車検',
            width: 'full',
            required: true,
          },
          {
            name: 'repair_history',
            label: '修復歴',
            type: 'checkbox',
            checkLabel: 'あり',
          },
          {
            name: 'warranty',
            label: '保証',
            width: 'full',
            required: true,
          },
        ],
      },
      values: {
        flag: 1,
        brand_id: null,
        vehicle_id: null,
        status_type: null,
        shape_type: null,
        regist_year: null,
        regist_month: null,
        model: null,
        identifier: null,
        grade: null,
        grade_comment: null,
        mission: null,
        displacement: null,
        engine: null,
        door_num: null,
        capacity: null,
        color_type: null,
        color_detail: null,
        color_name: null,
        size_body: null,
        size_luggage: null,
        body_num: null,
        mileage_type: null,
        mileage: null,
        mileage_before: null,
        mileage_after: null,
        inspection_type: null,
        inspection_year: null,
        inspection_month: null,
        repair_history: 0,
        price_ask: 0,
        price_total: null,
        price_body: null,
        maintenance: 0,
        maintenance_comment: null,
        warranty: null,
        warranty_limit: null,
        warranty_distance: null,
        warranty_year: null,
        warranty_month: null,
        options: null,
      },
    };
  },
  created() {
    this.getSalesCar();
    this.getBrands();
    this.getShops();
  },
  mounted() {
    // storeを監視してカテゴリ編集後getする
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'modal/contents/parentUpdate') {
        if (this.modal.contents.updateData.get === 'brand') {
          this.getBrands();
          this.values.brand_id = this.modal.contents.updateData.id;
        } else if (this.modal.contents.updateData.get === 'vehicle') {
          this.getVehicles();
          this.values.vehicle_id = this.modal.contents.updateData.id;
        }
      }
    });
  },
  watch: {
    selectedBrand() {
      this.getVehicles();
    },
    user: {
      handler() {
        this.getShops();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['helper', 'user', 'modal']),
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    selectedBrand() {
      return this.values.brand_id;
    },
    readySubmit() {
      const flag = {
        normal: false,
        color: false,
        price: false,
        inspection: false,
        mileage: false,
        warranty: false,
        shop: Boolean(this.shopId),
        flag: false,
      };

      // everyでの判定から除外
      const excludeItemName = ['color', 'inspection', 'mileage', 'warranty', 'price'];
      const allItems = [...this.formItems.left, ...this.formItems.right];
      const requiredItems = allItems.filter((item) => item.required && !excludeItemName.includes(item.name));
      flag.normal = requiredItems.every((item) => this.isValidValue(item.name));

      // 価格（price）
      flag.price = Boolean(this.values.price_ask || this.isValidValue('price_total'));

      // 車体色（color）
      flag.color = Boolean(this.values.color_type && this.values.color_detail);

      // 車検（inspection）
      flag.inspection = Boolean(this.values.inspection_type); // 選択されているか
      if (flag.inspection && this.values.inspection_type === 1) flag.inspection = Boolean(this.values.inspection_year && this.values.inspection_month);

      // 走行距離（mileage）
      flag.mileage = Boolean(this.values.mileage_type); // 選択されているか
      if (flag.mileage && this.values.mileage_type === 1) flag.mileage = this.isValidValue('mileage');
      if (flag.mileage && this.values.mileage_type === 4) flag.mileage = Boolean(this.isValidValue('mileage_after') && this.isValidValue('mileage_before'));

      // 保証（warranty）
      flag.warranty = Boolean(this.values.warranty !== null);
      if (flag.warranty && this.values.warranty === 1) {
        flag.warranty = Boolean(
          this.isValidValue('warranty_distance')
          && this.isValidValue('warranty_limit'),
        );
      }
      if (flag.warranty && this.values.warranty === 2) {
        flag.warranty = Boolean(
          this.isValidValue('warranty_distance')
          && this.isValidValue('warranty_year')
          && this.isValidValue('warranty_month'),
        );
      }

      // saleが紐づいている場合には掲載ステータスは不可に
      if (this.values.flag !== 1 || !this.flag.isSold) flag.flag = true;

      // 全てtrueになっているか確認
      return !this.flag.beforeUploadImages
        && Object.keys(flag).every((key) => flag[key])
        && (this.values.flag !== 1 || (this.values.flag === 1 && this.images.length));
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    isValidValue(name) {
      const allTypes = {
        number: ['flag', 'brand_id', 'vehicle_id', 'status_type', 'shape_type', 'regist_year', 'regist_month', 'mission', 'displacement', 'engine', 'door_num', 'capacity', 'mileage_type', 'mileage', 'mileage_before', 'mileage_after', 'inspection_type', 'inspection_year', 'inspection_month', 'repair_history', 'price_ask', 'price_total', 'price_body', 'maintenance', 'maintenance_comment', 'warranty', 'warranty_limit', 'warranty_distance', 'warranty_year', 'warranty_month'],
        string: ['model', 'identifier', 'grade', 'grade_comment', 'color_type', 'color_detail', 'color_name', 'size_body', 'size_luggage', 'body_num', 'options'],
      };
      const type = Object.keys(allTypes).find((key) => allTypes[key].includes(name));
      let validType = false;
      if (type === 'number' && (this.values[name] || this.values[name] === 0)) validType = !isNaN(this.values[name]);
      else if (type === 'string' && this.values[name]) validType = typeof this.values[name] === 'string';
      return validType;
    },
    carLabelObj(key) {
      if (!this.helper.master?.labels?.cars) return null;
      return key ? this.helper.master.labels.cars[key] : this.helper.master.labels.cars;
    },
    obj2Array(obj) {
      return obj ? Object.keys(obj).map((key) => Object({ label: obj[key], value: Number(key) || Number(key) === 0 ? Number(key) : key })) : null;
    },

    formatYears(type) {
      const arr = [];
      const jpYears = [
        { label: '昭和', start: 1926, end: 1988 },
        { label: '平成', start: 1989, end: 2018 },
        { label: '令和', start: 2019, end: 9999 },
      ];
      const nowYear = new Date().getFullYear();
      const n = type === 'before' ? nowYear - 1926 : 5;
      for (let i = 0; i < n; i += 1) {
        const year = type === 'before' ? nowYear - i : nowYear + i;
        const yearJP = jpYears.find((obj) => obj.start <= year && obj.end >= year);
        arr.push({ label: `${year}（${yearJP.label}${year - yearJP.start + 1}）`, value: year });
      }
      return arr;
    },
    formatMonthes() {
      const monthes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      return monthes.map((val) => Object({ label: val, value: val }));
    },

    options(name) {
      if (name === 'door_num') return [1, 2, 3, 4, 5].map((num) => Object({ label: num, value: num }));
      if (name === 'regist_year') return this.formatYears('before');
      if (name === 'inspection_year' || name === 'warranty_year') return this.formatYears('after');
      if (name === 'regist_month' || name === 'inspection_month' || name === 'warranty_month') return this.formatMonthes();
      if (name === 'brand_id') return this.brands.map((obj) => Object({ label: obj.label, value: obj.id, country: obj.country }));
      if (name === 'vehicle_id') return this.vehicles.map((obj) => Object({ label: obj.name, value: obj.id }));
      // カラー関連
      const colors = this.carLabelObj('colors') || [];
      if (name === 'color_type') return Object.keys(colors).map((key) => Object({ label: colors[key].label, value: key }));
      if (name === 'color_detail') return this.values.color_type && colors[this.values.color_type] ? this.obj2Array(colors[this.values.color_type].details) : [];
      // そのほか
      return this.obj2Array(this.carLabelObj(name));
    },
    changeValue(name, value) {
      this.values[name] = value;
      // color_typeが変更になった場合はcolor_detailをnullにする
      if (name === 'color_type') this.values.color_detail = null;
    },
    changeImageFlag(value) {
      this.flag.beforeUploadImages = value;
    },
    getSalesCar() {
      if (this.urlParam === 'regist') {
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 2, 11, 901],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.salesCar = res.detail;
          this.shopId = this.salesCar.shops[0]?.id || 0;
          if (!this.salesCar) {
            alert('販売車両が存在しませんでした。');
            this.$router.push('/sales-car');
          } else {
            this.setValues();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getShops() {
      if (this.urlParam === 'regist') this.shopId = this.user.shops?.[0]?.id || 0;
      if (!this.isSuperUser(this.user)) return;

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    setValues() {
      Object.keys(this.values).forEach((key) => {
        this.values[key] = this.salesCar[key];
      });
      this.images = [...this.salesCar.images];
      if (this.salesCar.sale) this.flag.isSold = true;
    },

    updateImages(images) {
      this.images = images;
    },

    getBrands() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    getVehicles() {
      if (!this.flag.isFirst) {
        this.values.vehicle_id = null;
      }
      this.flag.isFirst = false;
      
      if (!this.selectedBrand) {
        return;
      }

      const params = {
        brand_id: this.selectedBrand,
      };

      this.axios({
        method: 'GET',
        url: '/v1/vehicle/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.vehicles = res.vehicles.data;
          if (!this.vehicles?.length && confirm('該当ブランドは車種が未登録です。\n新規車種登録を行いますか?')) {
            this.openRegistModal('vehicle_id');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    regist() {
      if (this.values.flag === 999 && !confirm('販売車両情報を削除してよろしいですか？')) return;
      if (this.values.flag === 901 && !confirm('販売車両情報の掲載を中止してよろしいですか？')) return;
      if (this.values.flag === 11 && !confirm('成約済みにしてよろしいですか？')) return;
      this.showLoading();
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const imageIds = this.images.map((image) => image.id);
      const data = { ...this.values, images: imageIds };
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        data.id = this.salesCar.id;
      }
      data.shopData = {
        shop_id: this.shopId,
      };

      this.axios({
        method: 'POST',
        url: `/v1/salesCar/${endpoint}/`,
        data,
      })
        .then(() => {
          if (endpoint === 'create') {
            alert('販売車両情報を登録しました。');
          } else {
            alert('販売車両情報を更新しました。');
          }
          this.$router.push('/sales-car/');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    openRegistModal(name) {
      const data = {
        name: name.replace('_id', ''),
        brand_id: name === 'vehicle_id' ? this.selectedBrand : null,
      };
      const args = {
        modalName: 'RegistBrandVehicle',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module="s">
$bdr-color: var(--border-gray-opacity-1);
.forms {
  width: 50%;
  border-bottom: 1px solid $bdr-color;
  margin-bottom: -1px;
  &:first-child {
    border-right: 1px solid $bdr-color;
  }
  > li {
    > * {
      padding: 6px 12px;
    }
    &:not(:first-child) {
      border-top: 1px solid $bdr-color;
    }
  }
}
</style>
