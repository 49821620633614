<template>
  <div class="wrapper">
    <div :class="s.wrap">
      <GlobalHeader />
      <div class="contents" v-if="flag.roleChecked">
        <router-view />
      </div>
      <GlobalFooter />
      <LoadingsModal />
      <ContentsModal />
      <MessagesModal />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  GlobalHeader,
  GlobalFooter,
} from '@/components/layouts';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  data() {
    return {
      flag: {
        roleChecked: false,
      },
    };
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
  },
  computed: {
    ...mapState(['user', 'modal']),
  },
  watch: {
    user: {
      handler() {
        this.checkRole();
      },
      deep: true,
    },
    $route(to, from) {
      if (to.path !== from.path) this.flag.roleChecked = false;
      this.checkRole();
    },
  },
  methods: {
    checkRole() {
      // ログイン不要ページはacceptRoleを設けない
      const acceptRole = this.$route.meta?.acceptRole;
      if (!acceptRole) {
        this.flag.roleChecked = true;
        return;
      }
      // ユーザー情報セットされていなければreturn
      if (!(this.user.email || this.user.line_user_id)) return;
      // 閲覧権限なし
      if (!acceptRole.includes(this.user.role.role)) {
        alert('このページは閲覧権限がありません。ホームへリダイレクトします。');
        this.$router.push('/');
        return;
      }
      this.flag.roleChecked = true;
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/fonts.scss';
@import './assets/scss/_variables.scss';
// @import './assets/scss/_reset.scss';
// @import './assets/scss/_mq.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/style.scss';
</style>

<style lang="scss" module="s">
.wrap {
  position: relative;
  min-height: calc(100vh - var(--footer-height) - var(--footer-p-top));
}
</style>
