<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-clipboard me-2"/>車体情報{{ urlParam === 'regist' ? '登録' : '編集'}}</div>
    <spacer :y="2"/>

    <Loader :flag="flag.isLoading.get">
      <!-- 基本情報 -->
      <div class="bdr rounded-2" v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!flag.readySubmit"
              @click="regist(1)"><i class="fa-regular fa-download me-2"/>保存する</button>
          </div>
        </div>
        <!-- edit -->
        <div class="px-4 py-3">
          <VehicleForm
            ref="vehicleForm"
            :vehicle="vehicle"
            :showRegistBtn="false"
            v-model:formData="formData"
            @readySubmit="flag.readySubmit = $event"
            @editVehicle="flag.editVehicle = $event"
          />
        </div>
      </div>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  VehicleForm,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  mixins: [cf],
  components: {
    Spacer,
    VehicleForm,
    Loader,
  },
  data() {
    return {
      flag: {
        readySubmit: false,
        isLoading: {
          get: true,
        },
        editVehicle: false,
      },
      vehicle: null,
      formData: {
        baseData: [
          {
            name: 'name',
            label: '車名',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'brand_id',
            label: 'ブランド',
            type: 'select',
            options: [],
            required: true,
            value: 0,
          },
          {
            name: 'feature',
            label: '特徴',
            type: 'textarea',
            size: 'half',
            required: true,
            value: '',
          },
          {
            name: 'description',
            label: '詳細',
            type: 'textarea',
            size: 'half',
            required: true,
            value: '',
          },
        ],
        images: [],
      },
    };
  },
  created() {
    this.getVehicle();
    if (this.$route.query?.brand_id) this.formData.baseData[1].value = Number(this.$route.query.brand_id);
  },
  computed: {
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    vehicleData() {
      return cf.pullOutValues(this.formData.baseData);
    },
  },
  watch: {
    urlParam() {
      if (this.$route.href.includes('vehicle')) {
        this.getVehicle();
      }
    },
  },
  methods: {
    getVehicle() {
      if (this.urlParam === 'regist') {
        this.flag.editVehicle = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/vehicle/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (res.detail) {
            this.vehicle = res.detail;
            this.setValues();
          } else {
            alert('車両情報が見つかりませんでした。');
            this.$router.push('/brand/');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    setValues() {
      this.formData.baseData.forEach((item) => {
        item.value = this.vehicle[item.name];
      });
      this.formData.images = [...this.vehicle.images];
    },

    regist() {
      this.$refs.vehicleForm.regist(1);
    },
  },
};
</script>
<style lang="scss" module="s">

</style>
