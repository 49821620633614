<template>
  <div>
    <Form v-model:items="formItems.baseData"/>
    <div class="w-25">
      <div class="mt-3 mb-2">画像</div>
      <ImageForm
        :isModal="true"
        v-model:initMedias="formItems.images"
        :count="1"
        :max="1"
        @changeFlag="changeImageFlag"
        @changeData="updateImages" />
    </div>
    <div v-if="showRegistBtn">
      <spacer :y="4"/>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        :disabled="!readySubmit"
        @click="regist"><i class="fa-regular fa-circle-plus me-2"/>登録する</button>
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm ms-3"
        @click="hideModal"><i class="fa-regular fa-x me-2"/>キャンセル</button>
    </div>
  </div>
</template>

<script>
import {
  Form,
  ImageForm,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';
import { mapState } from 'vuex';

export default {
  name: 'BrandForm',
  mixins: [cf],
  components: {
    Form,
    ImageForm,
    Spacer,
  },
  props: {
    brand: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    showRegistBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      flag: {
        beforeUploadImages: false,
      },
      formItems: this.formData,
    };
  },
  created() {
    this.$emit('readySubmit', this.readySubmit);
    if (this.helper.master.labels?.countries) {
      this.init();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.init();
        }
      });
    }
  },
  watch: {
    formItems: {
      handler() {
        this.$emit('input', this.formItems);
      },
      deep: true,
    },
    readySubmit: {
      handler() {
        this.$emit('readySubmit', this.readySubmit);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['helper']),
    readySubmit() {
      return this.formItems.baseData.every((item) => !item.required || (item.required && item.value)) && !this.flag.beforeUploadImages;
    },
    urlParam() {
      if (!this.isBrandPage) return 'regist';
      return Number(this.$route.params.id) || 'regist';
    },
    isBrandPage() {
      return this.$route.href.includes('brand');
    },
  },
  methods: {
    init() {
      const country = this.formData.baseData.find((item) => item.name === 'country');
      country.options = this.helper?.master?.labels?.countries || [];
    },
    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },
    changeImageFlag(value) {
      this.flag.beforeUploadImages = value;
    },
    updateImages(images) {
      this.formItems.images = images;
    },
    regist() {
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const data = cf.pullOutValues(this.formItems.baseData);
      const imageIds = this.formItems.images.map((image) => image.id);

      const updateData = { ...data, images: imageIds };

      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.brand.id;
      }

      // nameはエンコード
      updateData.name = encodeURIComponent(updateData.name);

      this.axios({
        method: 'POST',
        url: `/v1/brand/${endpoint}/`,
        data: updateData,
      })
        .then((response) => {
          const res = response.data;
          if (endpoint === 'create') {
            alert('ブランド情報登録が完了しました。');
            if (this.isBrandPage) this.$router.replace(`/brand/${res.brandId}`);
            else this.$emit('hideModal', res.brandId);
          } else {
            alert('ブランド情報を更新しました。');
          }
          this.$emit('editBrand', false);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">

</style>
