<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-car me-3"/>買取車両一覧</div>
      <div>
        <router-link
          :to="'/purchase-car/regist'">
          <button
            type="button"
            class="btn btn-primary btn-sm"><i class="fa-regular fa-plus me-2"/>新規登録</button>
        </router-link>
      </div>
    </div>
    <spacer :y="2"/>

    <div class="d-flex align-items-start">
      <Loader class="flex-1 me-3" :flag="flag.isLoading.get">
        <div v-if="!flag.isLoading.get">
          <p v-if="!purchaseCars.length">※買取車両が存在しません。新規登録してください。</p>
          <ul class="list bdr rounded-2" v-else>
            <li>
              <div>
                <div>車両写真</div>
                <div>基本情報</div>
                <div>買取相場</div>
                <div>登録・更新日</div>
              </div>
            </li>
            <li
              v-for="pc of purchaseCars" :key="pc">
              <router-link :to="`/purchase-car/${pc.id}/`">
                <div>
                  <div :style="`background-image: url(${pc.images?.length ? pc.images[0].url : '/img/noimg.png'})`"/>
                </div>
                <div>
                  <div class="fw-bold">{{ pc.vehicle?.name }}</div>
                  <div class="fs-xs fw-bold fc-gray">{{ pc.brand.label }}</div>
                  <div class="fs-xs fc-gray n2br mt-2" v-if="pc.summary">
                    <div class="fw-bold">概要</div>
                    <div v-html="omitStr(pc.summary)"/>
                  </div>
                  <div class="fs-xs fc-gray n2br mt-2" v-if="pc.comment">
                    <div class="fw-bold">詳細</div>
                    <div v-html="omitStr(pc.comment)"/>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <div v-if="!pc.market_price_min && !pc.market_price_max">
                    <span class="fw-bold fs-lg me-1">未設定</span></div>
                  <div v-else>
                    <span class="fw-bold fs-lg me-1">{{ pc.market_price_min?.toLocaleString() || '未設定' }}</span>
                    <span class="me-1">〜</span>
                    <span class="fw-bold fs-lg me-1">{{ pc.market_price_max?.toLocaleString() || '未設定' }}</span>
                    <span class="fs-xs">万円</span>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column fs-sm">
                  <span :class="flagLabels[pc.flag].class">
                    {{ flagLabels[pc.flag].label }}
                  </span>
                  <spacer :y="1"/>
                  <div>登録日：{{ formatTimestamp(pc.created_at, 'YYYY/MM/DD') }}</div>
                  <div>更新日：{{ formatTimestamp(pc.updated_at, 'YYYY/MM/DD') }}</div>
                </div>
              </router-link>
            </li>
          </ul>
          <Pagination
            class="mt-4"
            :path="'/purchase-car/'"
            :page="page"
            :pageMax="pageMax"
            v-if="pageMax !== 1 && !flag.isLoading.get" />
        </div>
      </Loader>
      <!-- 検索 -->
      <div class="card w-25">
        <div class="card-header"><i class="fa-regular fa-magnifying-glass me-2"/>検索</div>
        <div class="card-body">
          <!-- フリーワード -->
          <div class="mb-3">
            <div class="fs-xs fc-gray fw-bold">フリーワード</div>
            <form class="input-group" @submit.prevent="changeKeyword()">
              <input
                class="form-control form-control-sm"
                type="text"
                v-model="keyword">
              <button
                type="submit"
                class="btn btn-primary btn-sm"><i class="fa-regular fa-magnifying-glass"/></button>
            </form>
          </div>
          <!-- その他セレクトアイテム -->
          <div
            class="mb-3"
            v-for="item in selectItems"
            :key="item.name">
            <div class="fs-xs fc-gray fw-bold">{{ item.label }}</div>
            <FormParts
              v-if="['brand', 'vehicle'].includes(item.name)"
              :options="selectOptions(item.name)"
              :disabled="item.name === 'vehicle' && !searchParams.brand"
              :name="`${item.name}_id`"
              :initValue="searchParams[item.name]"
              type="customedSelect"/>
            <select
              v-else
              :name="item.name"
              :id="item.name"
              v-model="searchParams[item.name]"
              :disabled="item.name === 'vehicle' && !searchParams.brand"
              class="form-control form-control-sm">
              <option :value="0">選択してください</option>
              <option
                v-for="opt in selectOptions(item.name)"
                :key="opt.value"
                :value="opt.value">{{ opt.label }}</option>
            </select>
          </div>
        </div>
        <div class="bdr-t text-center p-3">
          <div
            @click="initParams"
            class="fs-xs link-underline-red pointer">検索条件クリア</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
  Pagination,
  FormParts,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'purchase-car-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Pagination,
    FormParts,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      flagLabels: {
        1: {
          label: '掲載中',
          class: 'tag primary',
        },
        2: {
          label: '下書き',
          class: 'tag gray',
        },
        901: {
          label: '掲載中止',
          class: 'tag danger',
        },
      },
      purchaseCars: [],
      brands: [],
      selectItems: [
        {
          name: 'brand',
          label: 'ブランド',
        },
        {
          name: 'vehicle',
          label: '車種',
        },
      ],
      keyword: '',
      searchParams: {
        brand: 0,
        vehicle: 0,
      },
      page: 1,
      pageMax: 1,
    };
  },
  created() {
    this.getList();
    this.getSearchOptions();
  },
  computed: {
    vehicles() {
      const targetBrand = this.brands.find((brand) => brand.id === this.searchParams.brand);
      return targetBrand ? targetBrand.vehicles : [];
    },
  },
  watch: {
    searchParams: {
      handler() {
        const query = { page: 1 };
        Object.keys(this.searchParams).forEach((key) => {
          if (this.searchParams[key]) query[key] = this.searchParams[key];
        });
        if (this.keyword) query.keyword = this.keyword;
        this.$router.replace({ path: '/purchase-car/', query });
        this.getList();
      },
      deep: true,
    },
    $route: {
      handler() {
        const query = this.$route.query;
        if (query) {
          this.page = Number(query.page) || 1;
          this.keyword = query.keyword;
          Object.keys(this.searchParams).forEach((key) => {
            this.searchParams[key] = Number(query[key]) || 0;
          });
        }
        // クエリにページがない場合は追加してreplace
        if (this.$route.path === '/purchase-car/' && !query.page) this.$router.replace({ path: '/purchase-car/', query: { ...query, page: 1 } });
        this.getList();
      },
      immediate: true,
    },
    vehicles: {
      handler() {
        this.searchParams.vehicle = 0;
      },
    },
  },
  methods: {
    // descriptionの文末を…にする
    omitStr(str) {
      if (!str) return '';
      let omittedStr = str;
      if (str.length > 80) {
        omittedStr = str.substring(0, 80);
        omittedStr += '...';
      }
      return omittedStr;
    },
    selectOptions(name) {
      if (name === 'brand') return this[`${name}s`].map((item) => Object({ label: item.label || item.name, value: item.id, country: item.country }));
      if (name === 'vehicle') return this[`${name}s`].map((item) => Object({ label: item.label || item.name, value: item.id }));
    },
    initParams() {
      Object.keys(this.searchParams).forEach((key) => { this.searchParams[key] = 0; });
      this.keyword = '';
      this.page = 1;
      this.$router.replace({ path: '/purchase-car/' });
    },
    getList() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 2, 901],
        page: this.page,
        limit: 10,
      };

      if (this.searchParams.brand) params.brand_id = this.searchParams.brand;
      if (this.searchParams.vehicle) params.vehicle_id = this.searchParams.vehicle;
      if (this.keyword) {
        params.keyword = this.keyword.replace(/　/g, ' ').split(' ');
        params.targetColumn = ['brand.name', 'brand.label', 'vehicle.name', 'grade', 'grade_comment', 'color_name', 'summary', 'comment'];
      }

      this.axios({
        method: 'GET',
        url: '/v1/purchaseCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.purchaseCars = res.purchaseCars.data;
          this.pageMax = res.purchaseCars.meta.last_page;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getSearchOptions() {
      this.getBrands();
    },
    getBrands() {
      const params = {
        withVehicles: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    changeKeyword() {
      const query = {
        ...this.$route.query,
        keyword: this.keyword,
      };
      this.$router.replace({ path: '/purchase-car/', query });
      this.getList();
    },
    changeValue(name, value) {
      if (name === 'brand_id') this.searchParams.brand = value;
      else if (name === 'vehicle_id') this.searchParams.vehicle = value;
      else this.searchParams[name] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  overflow: hidden;
  > li {
    transition: all .3s;
    &:hover {
      background-color: var(--bg-gray-secondary);
    }
    &:first-child {
      background-color: var(--bg-gray);
    }
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray);
    }

    > * {
      display: flex;
      color: var(--label-primary);
      > * {
        padding: 12px;
        &:not(:first-child) {
          border-left: 1px solid var(--border-gray);
        }
      }

      > :nth-child(1) { // 写真
        width: 150px;
        > * {
          width: 100%;
          padding-top: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      > :nth-child(2) { // 基本情報
        flex: 1;
      }
      > :nth-child(3) { // 価格
        width: 175px;
      }
      > :nth-child(4) { // 登録・更新日
        width: 200px;
      }
    }
  }
}
.tag {
  font-weight: 700;
  padding: 4px 9px;
  &.danger {
    border: 1px solid #dc3545;
    color: #dc3545;
  }
  &.gray {
    border: 1px solid gray;
    color: gray;
  }
  &.primary {
    border: 1px solid #0d6efd;
    color: #0d6efd;
  }
}
.pointer {
  cursor: pointer;
}
</style>
