<template>
  <div class="container-lg">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-car me-3"/>販売車両一覧</div>
      <div>
        <router-link
          :to="'/sales-car/regist'">
          <button
            type="button"
            class="btn btn-primary btn-sm"><i class="fa-regular fa-plus me-2"/>新規登録</button>
        </router-link>
      </div>
    </div>

    <spacer :y="2"/>

    <div class="d-flex align-items-start" v-if="helper.master.labels">
      <Loader class="flex-1 me-3" :flag="flag.isLoading.get">
        <!-- 一覧 -->
        <div v-if="!flag.isLoading.get">
          <p v-if="!salesCars.length">※販売車両が存在しません。新規登録してください。</p>
          <ul class="list bdr rounded-2" v-else>
            <li>
              <div class="fs-sm fw-bold text-center">
                <div>車両写真</div>
                <div>基本情報</div>
                <div>価格</div>
                <div>登録・更新日</div>
              </div>
            </li>
            <li
              v-for="sc of salesCars" :key="sc">
              <router-link :to="`/sales-car/${sc.id}/`">
                <div>
                  <div
                    class="img"
                    :style="`background-image: url(${sc.images?.length ? sc.images[0].url : '/img/noimg.png'}); `"/>
                </div>
                <div>
                  <div class="fw-bold">{{ sc.vehicle?.name || '車両名なし' }}</div>
                  <div class="fs-xs fw-bold fc-gray">{{ sc.brand.label }}</div>
                  <div
                    class="text-decoration-underline fs-xs"
                    v-if="sc.flag === 1"
                    @click.prevent="openFrontSalesCarDetail(sc.slug)">{{ frontUrl(sc.slug) }}</div>
                  <div class="d-flex flex-wrap mt-2">
                    <div class="py-1 px-2 fs-xs bg-gray rounded-2 me-2 mb-2">年式：{{ sc.regist_year }}</div>
                    <div class="py-1 px-2 fs-xs bg-gray rounded-2 me-2 mb-2">排気量：{{ sc.displacement }}cc</div>
                    <div
                      class="py-1 px-2 fs-xs bg-gray rounded-2 me-2 mb-2"
                      v-if="sc.inspection_type === 1">車検：{{ sc.inspection_year }}({{ convertYearToJapaneseEra(sc.inspection_year) }})年{{ sc.inspection_month}}月</div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center flex-column">
                  <div
                    v-if="sc.price_ask === 0"
                    class="d-flex justify-content-center align-items-center flex-column">
                    <div><span class="fs-xs me-1">総額</span><span class="fw-bold fs-lg me-1">{{ sc.price_total?.toLocaleString() }}</span><span class="fs-xs">万円</span></div>
                    <div v-if="sc.price_body"><span class="fs-xs me-1">本体</span><span class="fw-bold fs-lg me-1">{{ sc.price_body?.toLocaleString() }}</span><span class="fs-xs">万円</span></div>
                    <div v-if="sc.price_body"><span class="fs-xs me-1">他</span><span class="fw-bold fs-lg me-1">{{ (sc.price_total - sc.price_body)?.toLocaleString() }}</span><span class="fs-xs">万円</span></div>
                  </div>
                  <div v-else><span class="fw-bold fs-lg">応相談</span></div>
                  <router-link
                    class="mt-2 btn btn-sm"
                    :class="sc.sale ? 'btn-outline-dark' : 'btn-outline-success'"
                    :to="`/sales/${sc.sale ? `${sc.sale.id}/` : `regist/?salesCar=${sc.id}`}`">{{ sc.sale ? '販売情報' : '購入者登録' }}</router-link>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column fs-sm">
                  <span :class="flagClasses[sc.flag]">
                    {{ helper?.master?.labels?.cars.flag[sc.flag] || '取得中...' }}
                  </span>
                  <div
                    class="fs-xs mt-2 bg-gray px-2"
                    v-if="isSuperUser(user)">{{ sc.shops?.[0]?.name }}</div>
                  <spacer :y="1"/>
                  <div>登録日：{{ formatTimestamp(sc.created_at, 'YYYY/MM/DD') }}</div>
                  <div>更新日：{{ formatTimestamp(sc.updated_at, 'YYYY/MM/DD') }}</div>
                </div>
              </router-link>
            </li>
          </ul>
          <Pagination
            class="mt-4"
            :path="'/sales-car/'"
            :page="page"
            :pageMax="pageMax"
            v-if="pageMax !== 1 && !flag.isLoading.get" />
        </div>
      </Loader>

      <!-- 検索 -->
      <div class="card w-25">
        <div class="card-header"><i class="fa-regular fa-magnifying-glass me-2"/>検索</div>
        <div class="card-body">
          <!-- フリーワード -->
          <div class="mb-3">
            <div class="fs-xs fc-gray fw-bold">フリーワード</div>
            <form class="input-group" @submit.prevent="changeKeyword()">
              <input
                class="form-control form-control-sm"
                type="text"
                v-model="keyword">
              <button
                type="submit"
                class="btn btn-primary btn-sm"><i class="fa-regular fa-magnifying-glass"/></button>
            </form>
          </div>
          <!-- 店舗（superuserのみ） -->
          <div
            class="mb-3"
            v-if="user.role && isSuperUser(user)">
            <div class="fs-xs fc-gray fw-bold">店舗</div>
            <select
              name="shop"
              id="shop"
              class="form-control form-control-sm"
              v-model="searchParams.shop">
              <option :value="0">選択してください</option>
              <option
                v-for="opt in shops"
                :key="opt.id"
                :value="opt.id">{{ opt.name }}</option>
            </select>
          </div>
          <!-- その他セレクトアイテム -->
          <div
            class="mb-3"
            v-for="item in selectItems"
            :key="item.name">
            <div class="fs-xs fc-gray fw-bold">{{ item.label }}</div>
            <FormParts 
              v-if="['brand', 'vehicle'].includes(item.name)"
              :options="selectOptions(item.name)"
              :disabled="item.name === 'vehicle' && !searchParams.brand"
              :name="`${item.name}_id`"
              :initValue="searchParams[item.name]"
              type="customedSelect"/>
            <select
              v-else
              :name="item.name"
              :id="item.name"
              v-model="searchParams[item.name]"
              :disabled="item.name === 'vehicle' && !searchParams.brand"
              class="form-control form-control-sm">
              <option :value="0">選択してください</option>
              <option
                v-for="opt in selectOptions(item.name)"
                :key="opt.value"
                :value="opt.value">{{ opt.label }}</option>
            </select>
          </div>
        </div>

        <div class="bdr-t text-center p-3">
          <div
            @click="initParams"
            class="fs-xs link-underline-red">検索条件クリア</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  Pagination,
  FormParts,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'sales-car-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Pagination,
    FormParts,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      flagClasses: {
        1: 'tag primary',
        2: 'tag gray',
        11: 'tag green',
        901: 'tag danger',
      },
      salesCars: [],
      shops: [],
      brands: [],
      selectItems: [
        {
          name: 'flag',
          label: 'ステータス',
        },
        {
          name: 'brand',
          label: 'ブランド',
        },
        {
          name: 'vehicle',
          label: '車種',
        },
      ],
      keyword: '',
      searchParams: {
        brand: 0,
        vehicle: 0,
        shop: 0,
        flag: 0,
      },
      page: 1,
      pageMax: 1,
    };
  },
  created() {
    this.getSearchOptions();
  },
  computed: {
    ...mapState(['user', 'helper']),
    carLabels() {
      return this.helper.master.labels?.cars || null;
    },
    vehicles() {
      const targetBrand = this.brands.find((brand) => brand.id === this.searchParams.brand);
      return targetBrand ? targetBrand.vehicles : [];
    },
  },
  watch: {
    user: {
      handler() {
        this.getSalesCars();
        this.getShops();
      },
      deep: true,
    },
    searchParams: {
      handler() {
        const query = { page: 1 };
        Object.keys(this.searchParams).forEach((key) => {
          if (this.searchParams[key]) query[key] = this.searchParams[key];
        });
        this.$router.replace({ path: '/sales-car/', query });
        this.getSalesCars();
      },
      deep: true,
    },
    $route: {
      handler() {
        const query = this.$route.query;
        if (query) {
          this.page = Number(query.page) || 1;
          this.keyword = query.keyword;
          Object.keys(this.searchParams).forEach((key) => {
            this.searchParams[key] = Number(query[key]) || 0;
          });
        }
        // クエリにページがない場合は追加してreplace
        if (this.$route.path === '/sales-car/' && !query.page) this.$router.replace({ path: '/sales-car/', query: { ...query, page: 1 } });
        this.getSalesCars();
      },
      immediate: true,
    },
    vehicles: {
      handler() {
        this.searchParams.vehicle = 0;
      },
    },
  },
  methods: {
    frontUrl(slug) {
      const frontBaseUrl = process.env.VUE_APP_FRONT_BASE_URL;
      return `${frontBaseUrl}/sales-car/${slug}/`;
    },
    openFrontSalesCarDetail(slug) {
      window.open(this.frontUrl(slug));
    },
    selectOptions(name) {
      if (name === 'brand') return this[`${name}s`].map((item) => Object({ label: item.label || item.name, value: item.id, country: item.country }));
      if (name === 'vehicle') return this[`${name}s`].map((item) => Object({ label: item.label || item.name, value: item.id }));
      return this.carLabels ? Object.keys(this.carLabels[name]).map((key) => Object({ label: this.carLabels[name][key], value: key })) : [];
    },
    initParams() {
      Object.keys(this.searchParams).forEach((key) => { this.searchParams[key] = 0; });
      this.page = 1;
    },
    getSalesCars() {
      if (!this.user.role || (!this.isSuperUser(this.user) && !this.user.shops[0])) return;
      if (!this.isSuperUser(this.user)) this.searchParams.shop = this.user.shops[0].id;

      this.flag.isLoading.get = true;

      // paramsの設定
      const params = {
        flags: this.searchParams.flag ? [this.searchParams.flag] : [1, 2, 11, 901],
        page: this.page,
        limit: 10,
      };
      if (this.searchParams.shop) params.shop_id = this.searchParams.shop;
      if (this.searchParams.brand) params.brand_id = this.searchParams.brand;
      if (this.searchParams.vehicle) params.vehicle_id = this.searchParams.vehicle;
      if (this.keyword) {
        params.keyword = this.keyword.replace(/　/g, ' ').split(' ');
        params.targetColumn = ['brand.name', 'brand.label', 'vehicle.name', 'grade', 'grade_comment', 'color_name'];
      }

      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.salesCars = res.salesCars.data;
          this.pageMax = res.salesCars.meta.last_page;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getSearchOptions() {
      this.getBrands();
      this.getShops();
    },
    getShops() {
      if (!this.isSuperUser(this.user)) return;
      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getBrands() {
      const params = {
        withVehicles: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    changeKeyword() {
      const query = {
        ...this.$route.query,
        keyword: this.keyword,
      };
      this.$router.replace({ path: '/sales-car/', query });
      this.getSalesCars();
    },
    changeValue(name, value) {
      if (name === 'brand_id') this.searchParams.brand = value;
      else if (name === 'vehicle_id') this.searchParams.vehicle = value;
      else this.searchParams[name] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  overflow: hidden;
  > li {
    transition: all .3s;
    &:hover {
      background-color: var(--bg-gray-secondary);
    }
    &:first-child {
      background-color: var(--bg-gray);
    }
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray);
    }

    > * {
      display: flex;
      color: var(--label-primary);
      > * {
        padding: 12px;
        &:not(:first-child) {
          border-left: 1px solid var(--border-gray);
        }
      }

      > :nth-child(1) { // 写真
        width: 150px;
        > * {
          width: 100%;
          padding-top: 100%;
          background-size: contain;
          background-position: center;
        }
      }
      > :nth-child(2) { // 基本情報
        flex: 1;
      }
      > :nth-child(3) { // 価格
        width: 150px;
      }
      > :nth-child(4) { // 登録・更新日
        width: 200px;
      }
    }
  }
}
.tag {
  font-weight: 700;
  padding: 4px 9px;
  &.danger {
    border: 1px solid #dc3545;
    color: #dc3545;
  }
  &.gray {
    border: 1px solid gray;
    color: gray;
  }
  &.green {
    border: 1px solid #198754;
    color: #198754;
  }
  &.primary {
    border: 1px solid #0d6efd;
    color: #0d6efd;
  }
}
.img {
  width: 100%;
  padding-top: 75%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
