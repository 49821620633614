<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-clipboard me-2"/>ブランド情報{{ urlParam === 'regist' ? '登録' : '詳細'}}</div>
    <spacer :y="2"/>

    <Loader :flag="flag.isLoading.get">
      <!-- 基本情報 -->
      <div class="bdr rounded-2" v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              v-if="flag.editBrand"
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!flag.readySubmit"
              @click="regist"><i class="fa-regular fa-circle-plus me-2"/>登録する</button>
            <!-- <button
              v-if="$route.params.id !== 'regist'"
              type="button"
              class="btn btn-primary btn-sm"><i class="fa-regular fa-circle-plus me-2"/>保存する</button> -->
            <button
              v-if="urlParam !== 'regist' && !flag.editBrand"
              type="button"
              class="btn btn-primary btn-sm"
              @click="flag.editBrand = true"><i class="fa-regular fa-pen-to-square me-2"/>編集する</button>
          </div>
        </div>
        <!-- edit -->
        <div class="px-4 py-3" v-if="flag.editBrand">
          <BrandForm
            ref="brandForm"
            :brand="brand"
            :showRegistBtn="false"
            v-model:formData="formData"
            @readySubmit="flag.readySubmit = $event"
            @editBrand="flag.editBrand = $event"
            />
        </div>
        <!-- display -->
        <div class="p-4" v-if="urlParam !== 'regist' && !flag.editBrand">
          <div class="mb-1 fw-bold fs-lg">{{ brandData.label }}【{{ adjustCountry(brandData.country) }}】</div>
          <div class="mb-2 fs-sm fw-bold fc-gray">{{ brandData.name }}</div>
          <div class="fs-sm fc-gray">{{ brandData.description }}</div>
        </div>
      </div>

      <spacer :y="4"/>

      <!-- vehicle情報 -->
      <div v-if="urlParam !== 'regist' && !flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="fw-bold fs-lg"><i class="fa-regular fa-car me-2"/>車体情報</div>
          <div>
            <router-link
              :to="`/vehicle/regist/?brand_id=${urlParam}`">
              <button
                type="button"
                class="btn btn-primary btn-sm">新規車体登録</button>
            </router-link>
          </div>
        </div>
        <ul :class="s.vehicles" v-if="brand?.vehicles.length">
          <li
            v-for="vehicle of brand.vehicles"
            :key="vehicle">
            <router-link
              :to="`/vehicle/${vehicle.id}/`"
              class="rounded-2 bdr overflow-hidden">
              <div :style="`background-image: url(${ vehicle.images?.length ? vehicle.images[0].url :  '/img/noimg.png'})`"/>
              <div class="p-3 text-center">{{ vehicle.name }}</div>
            </router-link>
          </li>
        </ul>
      </div>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
  BrandForm,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';
import { mapState } from 'vuex';

export default {
  mixins: [cf],
  components: {
    Spacer,
    BrandForm,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
        readySubmit: false,
        editBrand: false,
      },
      brand: null,
      formData: {
        baseData: [
          {
            name: 'label',
            label: 'ラベル',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'name',
            label: '英字表記',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'country',
            label: '国',
            type: 'select',
            options: [],
            required: true,
            value: 0,
          },
          {
            name: 'description',
            label: '詳細',
            type: 'textarea',
            size: 'half',
            required: false,
            value: '',
          },
        ],
        images: [],
      },
    };
  },
  created() {
    if (this.helper.master.labels?.countries) {
      this.getBrand();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.getBrand();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper']),
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    brandData() {
      return cf.pullOutValues(this.formData.baseData);
    },
  },
  methods: {
    adjustCountry(value) {
      const helperCountry = this.helper?.master?.labels?.countries?.find((country) => value === country.value);
      return helperCountry ? helperCountry.label : '';
    },
    getBrand() {
      const country = this.formData.baseData.find((item) => item.name === 'country');
      country.options = this.helper?.master?.labels?.countries || [];

      if (this.urlParam === 'regist') {
        this.flag.editBrand = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brand = res.detail;
          this.brand.name = decodeURIComponent(this.brand.name);
          this.setValues();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    setValues() {
      this.formData.baseData.forEach((item) => {
        item.value = this.brand[item.name];
      });
      this.formData.images = [...this.brand.images];
    },

    regist() {
      this.$refs.brandForm.regist();
    },
  },
};
</script>

<style lang="scss" module="s">
.vehicles {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  > li {
    width: calc(100% / 6);
    padding: 6px;
    > * {
      display: block;
      color: var(--label-primary);
      > :first-child {
        padding-top: 75%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
</style>
