<template>
  <div class="container sm">
    <spacer :y="12"/>
    <h2 class="text-center fs-4 fw-bold">ログイン</h2>
    <spacer :y="6"/>

    <!-- 通常ログイン -->
    <div>
      <form
        :class="user.login.vibration ? s.vibration : ''"
        @submit="submit"
        autocomplete="off">
        <div class="form-signin">
          <div class="form-floating">
            <input
              id="mail"
              type="email"
              name="mail"
              class="form-control"
              placeholder="メールアドレス"
              v-model.trim="$v.mail.$model"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
              >
            <label for="mail">メールアドレス</label>
          </div>
          <spacer :y="1" />
          <div class="form-floating">
            <input
              id="password"
              type="password"
              name="password"
              class="form-control"
              placeholder="パスワード"
              v-model.trim="$v.password.$model"
              v-bind:class="{ input_error: validationFlags.password }"
              v-on:input="invalidsCheck('password', $v.password.$invalid)"
              >
            <label for="password">パスワード</label>
          </div>
          <p
            v-if="$v.mail.$dirty
              && $v.mail.required.$invalid">メールアドレスを入力してください</p>
          <p
            v-if="$v.mail.$dirty
              && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
          <p
            v-if="$v.password.$dirty
              && $v.password.required.$invalid">パスワードを入力してください</p>
          <p
            v-if="user.login.isBanned && helper.master">パスワードを{{ helper.master.system.ban.count }}回間違えたため、{{ user.login.unbannedTime }}までログインが制限されています。<br>制限を解除したい場合は運営まで連絡してください。</p>
          <p
            v-if="user.login.isFailed === 1 && !user.login.isBanned">入力されたメールアドレスは登録されていません</p>
          <p
            v-if="user.login.isFailed === 2 && !user.login.isBanned">入力されたパスワードが間違っています</p>
          <spacer :y="3"/>
          <div>
            <basic-btn
              :disabled="!submitFlag"
              :width="'full'">ログイン</basic-btn>
            <spacer :y="1"/>
            <div class="text-center">
              <router-link
                to="/forgot-password/"
                class="fs-xs fc-black"><u>パスワードをお忘れですか？</u></router-link>
            </div>
            <spacer :y="6"/>
            <div class="text-center">
              <router-link
                to="/"
                class="fs-xs fc-black"><u>トップページへ戻る</u></router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import {
  BasicBtn,
  Spacer,
} from '@/components/parts';

export default {
  name: 'Login',
  mixins: [cf],
  components: {
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        mail: false,
        password: false,
      },
      invalids: {
        mail: true,
        password: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  created() {
    this.axios({
      method: 'GET',
      url: '/v1/connection/database',
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });

    if (this.$route.query
      && this.$route.query.expired) this.isExpired = true;
  },
  setup() {
    const mail = ref('');
    const password = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: { required },
    };

    const $v = useVuelidate(rules, { mail, password });

    return { mail, password, $v };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.password;
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        loginType: 'default',
        email: this.mail,
        password: this.password,
      };
      this.$store.dispatch('user/login/submit', data);
      this.$store.dispatch('user/update');
    },
  },
};
</script>

<style lang="scss" module>
// ログイン失敗時
.vibration {
  animation: vibration .1s  infinite;
}
@keyframes vibration {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  25% {transform: translate(2px, 2px) rotateZ(1deg)}
  50% {transform: translate(0px, 2px) rotateZ(0deg)}
  75% {transform: translate(2px, 0px) rotateZ(-1deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
</style>
