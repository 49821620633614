<template>
  <div :style="style">
    <div :class="[s.selected, s.list]" class="mb-3">
      <draggable
        v-if="max > 1"
        v-model="images"
        item-key="no"
        tag="ul"
        :animation="200">
        <template #item="{element, index}">
          <li>
            <div :style="`background-image: url(${element.url}); position:relative;`">
              <div
                :class="s.delete"
                v-on:click="deleteImage(index)"/>
            </div>
          </li>
        </template>
      </draggable>
      <p v-if="max > 1" class="fs-xs mt-3 mb-2">※ドラッグで表示順序を変更することができます。フォームの情報を保存すると変更が反映されます。
      </p>
      <ul v-else>
        <li
          v-for="(image, index) in images"
          :key="index">
          <div :style="`background-image: url(${image.url}); position:relative;`">
            <div
              :class="s.delete"
              v-on:click="deleteImage(index)"/>
          </div>
        </li>
      </ul>
    </div>

    <!-- 未アップロードの一覧 -->
    <div
      :class="[s.waiting, s.list]"
      v-if="max > images.length"
      v-on:dragover="dragover"
      v-on:drop="receiveFiles">
      <ul>
        <li
          v-for="(file, index) in beforeUploadFiles"
          :key="index">
          <div
            :style="`background-image: url(${file.base64}); position:relative;`">
            <div
              :class="s.delete"
              v-on:click="deleteFile(index)"/>
          </div>
        </li>
        <li v-if="isEnableInputImage">
          <input
            type="file"
            :id="inputId"
            :key="inputId"
            style="display: none"
            :multiple="max > 1"
            accept=".jpg, .jpeg, .png, .gif"
            v-on:change="receiveFiles">
          <label :for="inputId" :class="s.plus"/>
        </li>
      </ul>
      <div class="fs-xs mt-2" v-if="beforeUploadFilesLength">※ 登録・更新を行うには、未アップロードの画像をアップロードもしくは削除してください。</div>
      <div class="d-grid gap-2 mt-2" v-if="beforeUploadFilesLength">
        <button type="button" class="btn btn-sm btn-success" @click="uploadFiles">アップロードする</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Draggable from 'vuedraggable';

export default {
  name: 'image-form',
  components: {
    Draggable,
  },
  props: {
    max: {
      type: Number,
      default: 999999,
    },
    count: { // listで横に表示する数
      type: Number,
      default: 6,
    },
    initMedias: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      beforeUploadFiles: [],
      flag: {
        uploading: false,
      },
    };
  },
  computed: {
    ...mapState(['helper', 'user', 'modal']),
    style() {
      return `
        --list-item-width: calc(100% / ${this.count});
      `;
    },
    beforeUploadFilesLength() {
      return this.beforeUploadFiles.length;
    },
    isEnableInputImage() {
      return this.max - this.beforeUploadFiles.length - this.images.length > 0;
    },
    inputId() {
      return this.isModal ? 'image-form' : 'image';
    },
  },
  created() {
    this.images = this.initMedias;
  },
  watch: {
    beforeUploadFilesLength() {
      if (this.beforeUploadFilesLength > 0) {
        this.$emit('changeFlag', true);
      } else {
        this.$emit('changeFlag', false);
      }
    },
    images: {
      handler() {
        const changeLabel = this.isModal ? 'changeModalData' : 'changeData';
        this.$emit(changeLabel, this.images);
      },
      deep: true,
    },
    initMedias() {
      this.images = this.initMedias;
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    dragover(e) {
      e.preventDefault();
    },

    /** inputから選択した画像を受け取り */
    async receiveFiles(e) {
      e.preventDefault();
      if (!this.isEnableInputImage) return;

      this.showLoading();
      const inputFiles = e.target.files ? e.target.files : e.dataTransfer.files;
      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      let hasOverSize = false;

      let adjustInputFiles = inputFiles;
      const enableInputCount = this.max - this.beforeUploadFiles.length - this.images.length;
      if (inputFiles.length > enableInputCount) {
        adjustInputFiles = Array.from(inputFiles).slice(0, enableInputCount);
      }

      if (adjustInputFiles.length !== 0) {
        await Promise.all(
          Object.keys(adjustInputFiles).map(async (index) => {
            if (adjustInputFiles[index].size > limit) hasOverSize = true;
            else if (adjustInputFiles[index].type.includes('image')) await this.formatImage(adjustInputFiles[index]);
          }),
        );
      }
      if (hasOverSize) alert('ファイルサイズ上限は2MBです。');
      this.hideLoading();
    },

    /** 受け取った画像をbase64に変換 */
    formatImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Original = reader.result;
        // file保存・置換用
        this.beforeUploadFiles.push({
          file,
          base64: base64Original,
        });
      };
      reader.readAsDataURL(file);
    },

    /** アップロード前画像 削除 */
    deleteFile(i) {
      this.beforeUploadFiles.splice(i, 1);
    },
    /** アップロード・登録済み画像 削除 */
    deleteImage(i) {
      this.images.splice(i, 1);
    },

    /** 画像をS3にアップロード API */
    async uploadFiles() {
      this.flag.uploading = true;
      this.showLoading();

      let failedCount = 0;

      const originalError = [];
      await Promise.all(
        this.beforeUploadFiles.map(async (row) => {
          const form = new FormData();
          form.append('file', row.file);

          await this.axios({
            method: 'POST',
            url: '/v1/image/upload',
            data: form,
            params: {
              environment: this.helper.env.name,
              flag: 1,
              user_id: this.user.id,
              size_type: 1, // web用
              file_type: 1, // 画像
              alt: '',
            },
          })
            .then((response) => {
              const res = response.data.resultData;
              if (res.original.uploaded && res.original.uploaded.status === 200) {
                this.images.push({
                  id: res.original.imageId,
                  url: res.original.url,
                });
              } else if (res.original.uploaded && res.original.uploaded.status !== 200) {
                originalError.push(res.filename);
                failedCount += 1;
              }
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
              failedCount += 1;
            })
            .finally(() => {
            });
        }),
      )
        .then(async () => {
          // 複数画像アップロード時は1枚毎にrequestされるので、frontでアップロード終了を検知してtmp削除
          await this.axios({
            method: 'POST',
            url: '/v1/helper/delete/tmpDirectory',
            params: {
              path: `${this.helper.env.name}/Image/User/${this.user.id}`,
            },
          })
            .then(() => {
            })
            .catch((error) => {
              if (error.response) console.log(error.response.data);
              else console.log(error);
            });
        });
      if (originalError.length) {
        let images = '';
        originalError.forEach((err) => {
          images = `${images}${err}\n`;
        });
        alert(`以下の画像について、オリジナル画像のアップロードに失敗しました。\n${images}`);
      }

      this.beforeUploadFiles = [];
      this.flag.uploading = false;
      this.hideLoading();
      if (failedCount === 0) {
        alert('画像をアップロードしました。\n登録ボタンを押すと、情報と画像の紐付け情報が保存されます。');
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.list {
  &.waiting {
    padding: 12px;
    border: 2px dashed var(--border-gray);
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -12px;
    > li {
      width: var(--list-item-width);
      padding-bottom: 12px;
      > * {
        width: 100%;
        padding-top: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &.plus {
          background-color: var(--bg-gray);
          position: relative;
          cursor: pointer;
          &::after {
            content: '+';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 32px;
            color: var(--label-secondary);
          }
        }
      }
    }
  }
}
.delete {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: var(--gray);
  cursor: pointer;
  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 15px;
    background-color: #ffffff;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
</style>
