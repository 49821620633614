<template>
  <div>
    <form action="">
      <ul>
        <li
          class="mb-3"
          v-for="item in items" :key="item.name">
          <label
            class="fs-xs fc-gray"
            for="">
            {{ item.label }}
            <span
              :class="s.required"
              v-if="item.required"> ※必須項目</span>
          </label>

          <!-- formはtypeで出しわけ -->

          <!-- text -->
          <input
            v-if="item.type === 'text'"
            type="text"
            v-model="item.value">

          <!-- select -->
          <select
            v-if="item.type === 'select'"
            :name="item.name"
            :id="item.name"
            v-model="item.value">
            <option :value="0">選択してください</option>
            <option
              v-for="opt in item.options" :key="opt"
              :value="opt.value"
              :selected="opt.value === item.value">{{ opt.label }}</option>
          </select>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'address-form',
  mixins: [cf],
  components: {},
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      items: [
        {
          label: '郵便番号',
          name: 'zip',
          type: 'text',
          value: '',
        },
        {
          label: '都道府県',
          name: 'pref',
          type: 'select',
          options: [],
          value: '',
        },
        {
          label: '市区',
          name: 'city',
          type: 'text',
          value: '',
        },
        {
          label: '町村',
          name: 'town',
          type: 'text',
          value: '',
        },
        {
          label: '番地',
          name: 'address',
          type: 'text',
          value: '',
        },
        {
          label: '建物',
          name: 'building',
          type: 'text',
          value: '',
        },
      ],
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.init();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.init();
        }
      });
    }
  },
  computed: {
    ...mapState(['helper']),
    propValue() {
      return this.value;
    },
  },
  watch: {
    propValue: {
      handler() {
        this.init();
      },
      deep: true,
    },
    items: {
      handler() {
        this.$emit('changeAddressData', cf.pullOutValues(this.items));
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.items.forEach((item) => {
        item.value = this.propValue[item.name];
      });
      this.items[1].options = this.helper.master.prefs;
    },
  },
};
</script>

<style lang="scss" scoped>
input, select {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--border-gray);
  // background-color: var(--bg-gray-secondary);
  padding: .25rem .5rem;
  font-size: .875rem;
  &:focus  {
    outline: none;
  }
}
ul {
  display: flex;
  flex-wrap: wrap;
  > li {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0 8px;
    &:first-child {
      width: 100%;
      input {
        max-width: 12em;
      }
    }
    &:not(:first-child) {
      width: 33%;
    }
  }
}
</style>

<style lang="scss" module="s">
.required {
  color: red;
  font-size: 10px;
}

.label_input {
  max-width: 200px;
}
.type_select {
  max-width: 300px;
}
</style>
