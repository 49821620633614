<template>
  <header :class="s.header">
    <div :class="s.main">
      <div>
        <router-link
          to='/'
          :class="s.logo">CarBank管理画面</router-link>
      </div>
      <div class="d-flex align-items-center">
        <div class="fs-xs me-3">{{ user.email }}（{{ roleLabels[user.role?.role] }}）</div>
        <div class="btn btn-sm btn-outline-dark" @click="logout"><i class="fa-solid fa-right-from-bracket"/></div>
      </div>
    </div>
    <div :class="s.menu" v-if="$route && !notShowPageNames.includes($route.name)">
      <ul>
        <li
          v-for="(item, i) in menuItems"
          :key="i">
          <router-link
            :to="`${item.path}${item.path === '/' ? '' : '/'}`"
            :class="item.name === $route.name ? s.active : ''">
            <i :class="item.meta.icon" class="me-1"/>
            {{ item.meta.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'GlobalHeader',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      notShowPageNames: ['Login', 'signup/password'],
      roleLabels: {
        11: '店舗管理者',
        12: '店舗編集者',
        21: '特権管理者',
        22: '開発',
      },
    };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    menuItems() {
      if (!this.user.role) return [];
      const allRoutes = this.$router.options.routes;
      const groupName = [
        'Home',
        'Brand',
        // 'SalesCar',
        'Company',
        'PurchaseCar',
        'Customer',
        'User',
        // 'Sales',
        'News',
      ];
      const groups = allRoutes.filter((route) => route.meta && groupName.some((name) => route.meta.name.includes(name)) && !route.meta?.name.includes('Detail'));
      const groupsFilteredByRole = groups.filter((group) => !group.meta?.acceptRole || group.meta.acceptRole.includes(this.user.role.role));

      // superuserでない場合、店舗情報編集ページを追加（企業詳細にアクセスできないためここに導線を設ける）
      if (!this.isSuperUser(this.user) && this.user.shops[0]?.id) {
        const routeData = {
          path: `/shop/${this.user.shops[0].id}`,
          name: 'ShopDetail',
          meta: {
            icon: 'fa-regular fa-building',
            label: '店舗情報',
            name: 'ShopDetail',
          },
        };
        groupsFilteredByRole.push(routeData);
      }

      return groupsFilteredByRole;
    },
  },
  created() {
  },
  methods: {
    /** ログアウト */
    logout(e) {
      e.preventDefault();
      this.$store.dispatch('user/login/logout');
    },
  },
};
</script>

<style lang="scss" module="s">
.header {
  background-color: var(--bg-gray-secondary);
  border-bottom: 1px solid var(--border-gray);
  padding: 16px 24px 0;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.logo {
  color: var(--label-primary);
  font-weight: 700;
  text-decoration: none;
}

.menu {
  display: flex;
  align-items: center;
  > ul {
    display: flex;
    font-size: 14px;
    > li {
      > * {
        display: block;
        color: var(--label-primary);
        padding: 0 12px 4px;
        cursor: pointer;
        &.active {
          border-bottom: 2px solid var(--primary-blue);
          font-weight: bold;
        }
      }
    }
  }
}
</style>
