<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-clipboard me-2"/>店舗情報{{ urlParam === 'regist' ? '登録' : '編集'}}</div>
    <spacer :y="2"/>

    <!-- 基本情報 -->
    <div class="bdr rounded-2">
      <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
        <div class="fw-bold">基本情報</div>
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            :disabled="!readySubmit"
            @click="regist(1)"><i class="fa-regular fa-download me-2"/>保存する</button>
        </div>
      </div>
      <!-- edit -->
      <div class="px-4 py-3">
        <div class="d-flex align-items-stretch">
          <Form
            class="flex-1"
            v-model:items="baseData"
            @changeSubmitFlag="changeFormSubmitFlag" />
          <spacer :x="2"/>
          <div class="flex-1">
            <div v-if="!flag.isLoading.get">
              <label class="fs-xs fw-bold fc-gray">画像</label>
              <ImageForm
                :initMedias="images"
                :count="3"
                @changeFlag="changeImageFlag"
                @changeData="updateImages" />
            </div>
            <div class="d-flex flex-column align-items-start">
              <label class="fs-xs fw-bold fc-gray mt-3">付加情報</label>
              <button
                type="button" class="btn btn-primary btn-sm mt-2"
                @click="addExplanation">追加する</button>
              <div class="card w-100 mt-2"
                v-for="(exp, index) in explanations"
                :key="exp">
                <div class="px-3 pt-2">
                  <div :class="s.cross" @click="deleteExplanation(index)"><i class="fa-regular fa-xmark"/></div>
                  <Form
                    v-model:items="exp.formItems"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="urlParam !== 'regist' && isSuperUser(user)">
          <spacer :y="4"/>
          <div class="bdr-t pt-3 d-flex justify-content-center">
            <div
              class="text-decoration-underline pointer fc-red fs-sm"
              @click="regist(999)">削除する</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Form,
  ImageForm,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'shop-detail',
  mixins: [cf],
  components: {
    Spacer,
    Form,
    ImageForm,
  },
  data() {
    return {
      readySubmitForm: false,
      flag: {
        isLoading: {
          get: true,
        },
        editShop: false,
        beforeUploadImages: false,
      },
      shop: null,
      companies: null,
      baseData: [
        {
          name: 'name',
          label: '店舗名',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'company_id',
          label: '企業',
          type: 'select',
          options: [],
          required: true,
          value: 0,
        },
        {
          name: 'address',
          label: '所在地',
          required: true,
          requiredLabel: '番地まで必須入力',
          type: 'address',
          value: {
            zip: null,
            pref: null,
            town: null,
            city: null,
            address: null,
            building: null,
          },
        },
        {
          name: 'summary',
          label: '概要',
          type: 'textarea',
          size: 'half',
          required: true,
          value: '',
        },
        {
          name: 'description',
          label: '詳細',
          type: 'textarea',
          size: 'half',
          required: true,
          value: '',
        },
        {
          name: 'tel',
          label: '電話番号',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'fax',
          label: 'FAX番号',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'hours',
          label: '営業時間',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'holiday',
          label: '定休日',
          type: 'text',
          required: true,
          value: '',
        },
      ],
      images: [],
      explanations: [],
    };
  },
  created() {
    this.getCompanies();
    if (this.$route.query?.company_id) this.baseData[1].value = Number(this.$route.query.company_id);
  },
  computed: {
    ...mapState(['user']),
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    shopData() {
      return cf.pullOutValues(this.baseData);
    },
    readySubmit() {
      return this.readySubmitForm && !this.flag.beforeUploadImages;
    },
  },
  watch: {
    user: {
      handler() {
        if (!this.user.role) return;
        // superuserでなく、自店舗でない場合には閲覧権限なし → ホームへリダイレクト
        if (!this.isSuperUser(this.user) && this.user.shops[0]?.id !== Number(this.$route.params.id)) {
          alert('閲覧権限がありません。ホームへリダイレクトします。');
          this.$router.push('/');
          return;
        }
        this.getShop();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getShop() {
      if (this.urlParam === 'regist') {
        this.flag.editShop = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (res.detail) {
            this.shop = res.detail;
            this.setValues();
          } else {
            alert('店舗情報が見つかりませんでした。');
            this.$router.push('/company/');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getCompanies() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/company/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.companies = res.companies.data;
          this.baseData[1].options = this.companies;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },

    setValues() {
      this.baseData.forEach((item) => {
        if (item.name === 'address') {
          Object.keys(item.value).forEach((addressItem) => {
            item.value[addressItem] = this.shop[addressItem];
          });
        } else {
          item.value = this.shop[item.name];
        }
      });
      this.images = [...this.shop.images];
      this.shop.explanations.forEach((expObj) => {
        this.addExplanation(expObj);
      });
    },

    updateImages(images) {
      this.images = images;
    },

    addExplanation(obj) {
      this.explanations.push({
        id: obj?.id || null,
        formItems: [
          {
            name: 'title',
            label: 'タイトル',
            type: 'text',
            required: true,
            value: obj?.title || '',
          },
          {
            name: 'content',
            label: '内容',
            type: 'textarea',
            required: true,
            value: obj?.content || '',
          },
        ],
      });
    },

    deleteExplanation(index) {
      this.explanations.splice(index, 1);
    },

    changeFormSubmitFlag(bool) {
      this.readySubmitForm = bool;
    },

    changeImageFlag(value) {
      this.flag.beforeUploadImages = value;
    },

    regist(flag) {
      if (flag === 999 && !confirm('店舗情報を削除してよろしいですか？')) return;
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const imageIds = this.images.map((image) => image.id);

      let updateData = { flag };
      if (flag === 1) {
        const data = cf.pullOutValues(this.baseData);
        const address = data.address;
        delete data.address;

        const explanations = this.explanations.map((exp) => {
          const expData = cf.pullOutValues(exp.formItems);
          expData.id = exp.id;
          return expData;
        });

        updateData = {
          flag,
          images: imageIds,
          ...data,
          ...address,
          explanations,
        };
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.shop.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/shop/${endpoint}/`,
        data: updateData,
      })
        .then(() => {
          if (endpoint === 'create') {
            alert('店舗情報登録が完了しました。');
            this.$router.push(`/company/${updateData.company_id}`);
          } else {
            alert(`店舗情報を${flag === 999 ? '削除' : '更新'}しました。`);
            if (flag === 999) {
              this.$router.push(`/company/${updateData.company_id}`);
            }
          }
          this.flag.editShop = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.cross {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid var(--label-primary);
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
}
</style>
