<template>
  <div class="container sm">
    <spacer :y="12"/>
    <h2 class="text-center fs-4 fw-bold">パスワード再発行</h2>
    <spacer :y="6"/>

    <div
      class="wrap-error text-center"
      v-if="invalid">
      <p class="fs-xs">アクティベートキーが無効です。<br>再度パスワード再設定リクエストを送信してください。</p>
      <router-link
        to="/forgot-password/"
        class="fs-xs text-decoration-underline mt-4 d-block">再設定リクエスト送信ページはこちら</router-link>
    </div>

    <form
      v-if="!invalid"
      autocomplete="off">
      <div
        class="form-floating"
        v-if="!flag.sended && !flag.updated">
        <input
          id="password"
          type="password"
          name="password"
          class="form-control"
          placeholder="パスワード"
          v-model.trim="$v.password.$model"
          v-bind:class="{ input_error: validationFlags.password }"
          v-on:input="invalidsCheck('password', $v.password.$invalid)">
        <label for="password">パスワード</label>
      </div>

      <p
        class="fs-xs mt-3 text-center"
        v-if="$v.password.$dirty && $v.password.minLength.$invalid">
        パスワードは8文字以上で設定してください</p>

      <div
        v-if="!flag.sended && !flag.updated"
        class="mt-5 text-center">
        <button
          class="btn btn-primary"
          @click="submit"
          v-bind:disabled="!submitFlag">登録する
        </button>
      </div>

      <div
        v-if="flag.sended
          && flag.expired
          && !flag.updated"
        class="text-center">
        <p class="fs-xs">パスワード再設定の有効期限が切れています。再度パスワード再設定リクエストをお送りください。</p>
        <router-link
          to="/forgot-password/"
          class="fs-xs text-decoration-underline mt-4 d-block">再設定リクエスト送信ページはこちら</router-link>
      </div>

      <div
        v-if="flag.sended
          && !flag.expired
          && !flag.updated"
          class="text-center">
        <p class="fs-xs">パスワード再発行のためのアクティベートキーが無効です。再度パスワード再設定リクエストをお送りください。</p>
        <router-link
          to="/forgot-password/"
          class="fs-xs text-decoration-underline mt-4 d-block">再設定リクエスト送信ページはこちら</router-link>
      </div>

      <div
        v-if="flag.sended
          && flag.updated"
        class="text-center">
        <p class="fs-xs">パスワードを更新しました。<br>ログインページよりログインしてください。</p>
        <router-link
          to="/login/?isResetPass=true"
          class="fs-xs text-decoration-underline mt-4 d-block">ログインページはこちら</router-link>
      </div>

    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import {
  Spacer,
} from '@/components/parts';

export default {
  name: 'reset-password',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        password: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        updated: false,
        expired: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.secret) {
      this.invalid = false;
      this.activatekey = query.secret;
    }
  },
  setup() {
    const password = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { password });
    return { password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password && !this.flag.updated && !this.flag.expired;
    },
  },
  methods: {
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    submit(e) {
      e.preventDefault();
      if (!this.submitFlag) return;

      this.showLoading();
      // validateチェックは入力時に行われてる
      const data = {
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/resetPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.expired) this.flag.expired = true;
          } else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
</style>
