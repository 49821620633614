<template>
  <div class="home container">
    <ul class="row">
      <li
        v-for="(item, i) of menuItems"
        :key="i"
        class="col-3 mb-2">
        <router-link
          class="d-flex fc-black"
          :to="`${item.path}/`">
          <i class="me-3 fs-lg mt-1" :class="item.meta.icon"/>
          <div class="flex-1">
            <div class="fs-lg fw-bold mb-2">{{ item.meta.label }}</div>
            <div class="fs-sm">情報の登録・編集・削除が可能です。</div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    menuItems() {
      if (!this.user.role) return [];
      const allRoutes = this.$router.options.routes;
      const groupName = [
        'Brand',
        // 'SalesCar',
        'Company',
        'PurchaseCar',
        'Customer',
        'User',
        // 'Sales',
        'News',
      ];
      const groups = allRoutes.filter((route) => route.meta && groupName.some((name) => route.meta.name.includes(name)) && !route.meta?.name.includes('Detail'));
      const groupsFilteredByRole = groups.filter((group) => !group.meta?.acceptRole || group.meta.acceptRole.includes(this.user.role.role));

      // superuserでない場合、店舗情報編集ページを追加（企業詳細にアクセスできないためここに導線を設ける）
      if (!this.isSuperUser(this.user) && this.user.shops[0]?.id) {
        const routeData = {
          path: `/shop/${this.user.shops[0].id}`,
          meta: {
            icon: 'fa-regular fa-building',
            label: '店舗情報',
            name: 'ShopDetail',
          },
        };
        groupsFilteredByRole.push(routeData);
      }

      return groupsFilteredByRole;
    },
  },
  created() {
  },
  methods: {
    /** ログアウト */
    logout(e) {
      e.preventDefault();
      this.$store.dispatch('user/login/logout');
    },
  },
};
</script>


<style lang="scss" scoped>
li {
  > a {
    transition: all .3s;
    padding: 12px;
    border-radius: 4px;
    &:hover {
      background-color: #e2e2e2;
    }
  }
}
</style>
