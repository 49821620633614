<template>
  <div :class="s.wrap">
    <div
      @click="changeShowOptionsFlag(!this.flag.showOptions)"
      :id="name"
      :class="[
        s.btn,
        disabled ? s.disabled : '',
        required && !disabled ? s.required : '',
      ]">{{ selectedLabel }}</div>
    <div
      :class="s.options"
      v-if="flag.showOptions">
      <input
        type="text"
        class="form-control form-control-sm"
        placeholder="ブランド名・国名"
        v-model="keyword"
        :id="`${name}-input`">
      <ul>
        <li
          v-for="item in filteredOptions"
          :key="item.value"
          @click="changeValue(item.value)">
          <div
            v-if="name === 'brand_id'"
            :class="s.nationalFlag"
            :style="`background-image: url(/img/nationalFlags/${item.country}.png);`"/>
          <div>{{ item.label }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'select-form',
  props: {
    name: {
      type: String,
    },
    options: {
      type: Array,
    },
    initValue: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
  },
  data() {
    return {
      flag: {
        showOptions: false,
      },
      value: 0,
      keyword: '',
    };
  },
  created() {
    this.value = this.initValue;
  },
  mounted() {
    window.addEventListener('click', this.closeOptions);
  },
  unmounted() {
    window.removeEventListener('click', this.closeOptions);
  },
  computed: {
    ...mapState(['helper']),
    selectedLabel() {
      const selected = this.options.find((opt) => opt.value === this.value);
      if (!selected) return '選択してください';
      return selected.label;
    },
    filteredOptions() {
      let options = this.options;
      const countries = this.helper.master.labels?.countries || [];
      if (this.keyword) {
        options = options.filter((opt) => {
          const label = opt.label.includes(this.keyword);
          let country = false;
          const optCountry = countries.find((c) => c.value === opt.country);
          if (optCountry) country = optCountry.label.includes(this.keyword);
          return label || country;
        });
      }
      return options;
    },
  },
  watch: {
    initValue() {
      this.value = this.initValue;
    },
  },
  methods: {
    changeShowOptionsFlag(bool) {
      if (bool && this.disabled) return;
      this.flag.showOptions = bool;
      if (this.flag.showOptions) {
        this.$nextTick(() => {
          const input = document.getElementById(`${this.name}-input`);
          if (input) input.focus();
        });
      }
    },
    changeValue(v) {
      this.value = v;
      this.$emit('changeValue', this.value);
    },
    closeOptions(event) {
      const btn = document.getElementById(this.name);
      const input = document.getElementById(`${this.name}-input`);
      if (!input) return;
      if (btn.id !== event.target.id && input.id !== event.target.id) this.changeShowOptionsFlag(false);
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: relative;
  min-width: 200px;
}

.btn {
  padding: .25rem calc(.5rem + 12px) .25rem .5rem;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-color: transparent var(--label-secondary) var(--label-secondary) transparent ;
  }

  &.disabled {
    cursor: default;
    background-color: #e9ecef;
  }

  &.required {
    background-color: var(--bg-red);
  }
}

.options {
  margin-top: .25rem;
  padding: .25rem .25rem 0 .25rem;
  position: absolute;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  input {
    &:focus {
      box-shadow: none;
      border-color: #dee2e6;
    }
  }

  > ul {
    padding: .25rem;
    max-height: 240px;
    overflow-y: scroll;
    > li {
      padding: .25rem 0;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-top: 1px solid #dee2e6;
      }
      &:hover {
        color: var(--label-secondary);
      }

      .nationalFlag {
        width: 21px;
        height: 14px;
        border: 1px solid #dee2e6;
        background-position: center;
        background-size: contain;
        margin-right: 4px;
      }
    }
  }
}
</style>
