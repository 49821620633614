import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
/** @Account */
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';
import ForgotPassword from '@/views/Account/ForgotPassword.vue';
import ResetPassword from '@/views/Account/ResetPassword.vue';

/** @Company */
import CompanyList from '@/views/Company/List.vue';
import CompanyDetail from '@/views/Company/Detail.vue';
import ShopDetail from '@/views/Company/Shop.vue';

/** @Brand */
import BrandList from '@/views/Brand/List.vue';
import BrandDetail from '@/views/Brand/Detail.vue';
import BrandVehicleDetail from '@/views/Brand/Vehicle.vue';

/** @SalesCar */
import SalesCarList from '@/views/SalesCar/List.vue';
import SalesCarDetail from '@/views/SalesCar/Detail.vue';

/** @Users */
import UsersList from '@/views/Users/List.vue';

/** @PurchaseCar */
import PurchaseCarList from '@/views/PurchaseCar/List.vue';
import PurchaseCarDetail from '@/views/PurchaseCar/Detail.vue';

/** @Customer */
import CustomerList from '@/views/Customer/List.vue';
import CustomerDetail from '@/views/Customer/Detail.vue';

/** @Sales */
import SalesList from '@/views/Sales/List.vue';
import SalesDetail from '@/views/Sales/Detail.vue';

/** @News */
import NewsList from '@/views/News/List.vue';
import NewsDetail from '@/views/News/Detail.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name: 'Home',
      label: 'ホーム',
      icon: 'fa-regular fa-house',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    children: [
      {
        path: '/signup/password',
        name: 'signup/password',
        component: SignupPassword,
      },
    ],
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      name: 'ForgotPassword',
      label: 'パスワード再発行',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      name: 'ResetPassword',
      label: 'パスワード再設定',
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/company',
    name: 'CompanyList',
    component: CompanyList,
    meta: {
      name: 'CompanyList',
      label: '企業',
      icon: 'fa-regular fa-building',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/company/:id',
    name: 'CompanyDetail',
    component: CompanyDetail,
    meta: {
      name: 'CompanyDetail',
      label: '企業詳細',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/shop/:id',
    name: 'ShopDetail',
    component: ShopDetail,
    meta: {
      name: 'ShopDetail',
      label: '店舗詳細',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/brand',
    name: 'BrandList',
    component: BrandList,
    meta: {
      name: 'BrandList',
      label: 'ブランド',
      icon: 'fa-regular fa-copyright',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/brand/:id',
    name: 'BrandDetail',
    component: BrandDetail,
    meta: {
      name: 'BrandDetail',
      label: 'ブランド詳細',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/vehicle/:id',
    name: 'BrandVehicleDetail',
    component: BrandVehicleDetail,
    meta: {
      name: 'BrandVehicleDetail',
      label: '車体詳細',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/sales-car',
    name: 'SalesCarList',
    component: SalesCarList,
    meta: {
      name: 'SalesCarList',
      label: '販売車両',
      icon: 'fa-regular fa-car',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/sales-car/:id',
    name: 'SalesCarDetail',
    component: SalesCarDetail,
    meta: {
      name: 'SalesCarDetail',
      label: '販売車両詳細',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/users',
    name: 'UsersList',
    component: UsersList,
    meta: {
      name: 'UsersList',
      label: '管理者',
      icon: 'fa-solid fa-user',
      acceptRole: [11, 21, 22],
    },
  },
  {
    path: '/purchase-car',
    name: 'PurchaseCarList',
    component: PurchaseCarList,
    meta: {
      name: 'PurchaseCarList',
      label: '買取車両一覧',
      icon: 'fa-regular fa-truck-pickup',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/purchase-car/:id',
    name: 'PurchaseCarDetail',
    component: PurchaseCarDetail,
    meta: {
      name: 'PurchaseCarDetail',
      label: '買取車両詳細',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/customer',
    name: 'CustomerList',
    component: CustomerList,
    meta: {
      name: 'CustomerList',
      label: '顧客',
      icon: 'fa-solid fa-user-tie',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/customer/:id',
    name: 'CustomerDetail',
    component: CustomerDetail,
    meta: {
      name: 'CustomerDetail',
      label: '顧客詳細',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/sales',
    name: 'SalesList',
    component: SalesList,
    meta: {
      name: 'SalesList',
      label: '販売情報',
      icon: 'fa-regular fa-file-contract',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/sales/:id',
    name: 'SalesDetail',
    component: SalesDetail,
    meta: {
      name: 'SalesDetail',
      label: '販売情報詳細',
      acceptRole: [11, 12, 21, 22],
    },
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList,
    meta: {
      name: 'NewsList',
      label: 'お知らせ',
      icon: 'fa-regular fa-newspaper',
      acceptRole: [21, 22],
    },
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      name: 'NewsDetail',
      label: 'お知らせ詳細',
      acceptRole: [21, 22],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
