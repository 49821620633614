<template>
  <div class="container sm">
    <spacer :y="12"/>
    <h2 class="text-center fs-4 fw-bold">パスワードリセット</h2>
    <spacer :y="6"/>
    <div>
      <form autocomplete="off">
        <div class="form-signin">
          <p
            v-if="!flag.sended"
            class="fs-xs mb-2 text-center">登録しているメールアドレスを入力してください</p>
          <div class="form-floating">
            <input
              id="mail"
              class="form-control"
              type="email"
              name="mail"
              placeholder="メールアドレス"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-model.trim="$v.mail.$model"
              v-bind:disabled="flag.sended && flag.updated"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
            <label for="mail">メールアドレス</label>
          </div>
          <div v-if="$v.mail.$dirty && $v.mail.email.$invalid">
            <spacer :y="2"/>
            <p class="fs-xs fc-red text-center">正しいメールアドレスの形式で入力してください</p>
          </div>

          <spacer
            :y="4"
            v-if="flag.sended && flag.updated"/>
          <p
            class="fs-xs"
            v-if="flag.sended && flag.updated">
            ご登録のメールアドレスへパスワードリセット用のメールを送信しました。メールに記載されているURLからパスワードのリセットを行ってください。</p>
        </div>

        <spacer :y="6"/>

        <div v-if="!flag.updated" class="text-center">
          <button
            class="btn btn-primary"
            @click="submit"
            v-bind:disabled="!submitFlag">送信する</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import {
  Spacer,
} from '@/components/parts';

export default {
  name: 'forgot-password',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
      flag: {
        sended: false,
        updated: false,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });

    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !(this.flag.sended && this.flag.updated);
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** バイブレーションオン */
    vibration() {
      this.$store.dispatch('user/login/loginFailed');
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      if (!this.submitFlag) return;

      this.showLoading();
      const data = { email: this.mail };

      this.axios({
        method: 'POST',
        url: '/v1/user/forgotPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
        })
        .catch((error) => {
          this.vibration();
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
</style>
