<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-building me-3"/>お知らせ情報{{ urlParam === 'regist' ? '登録' : '編集'}}</div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <div class="bdr rounded-2" v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              v-if="flag.editNews"
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!readySubmit"
              @click="regist(1)"><i class="fa-regular fa-download me-1"/>保存する</button>
            <button
              v-if="flag.editNews"
              type="button"
              class="btn btn-outline-secondary ms-2 btn-sm"
              :disabled="!readySubmit"
              @click="regist(2)"><i class="fa-regular fa-floppy-disk me-2"/>下書き保存</button>
            <button
              v-if="flag.editNews && urlParam !== 'regist'"
              type="button"
              class="btn btn-outline-secondary btn-sm ms-2"
              @click="flag.editNews = !flag.editNews"><i class="fa-regular fa-x me-1"/>キャンセル</button>
            <button
              v-if="urlParam !== 'regist' && !flag.editNews"
              type="button"
              class="btn btn-primary btn-sm"
              @click="flag.editNews = true"><i class="fa-regular fa-pen-to-square me-2"/>編集する</button>
          </div>
        </div>
        <!-- edit -->
        <div class="px-4 py-3" v-if="flag.editNews">
          <Form
            v-model:items="baseData" />
          <div class="mt-3">
            <label
              class="fs-xs fw-bold fc-gray"
              for="">掲載日
              <span :class="s.required"> ※必須項目</span>
            </label>
            <spacer :y="1"/>
            <p >掲載日: {{ publish_date.year }}年{{ publish_date.month }}月{{ publish_date.day }}日</p>
            <spacer :y="1"/>
            <Calendar
              v-model:pickedDate="publish_date"/>
          </div>
          <div v-if="urlParam !== 'regist'">
            <spacer :y="4"/>
            <div class="bdr-t pt-3 d-flex justify-content-center">
              <div
                class="text-decoration-underline pointer fc-red fs-sm"
                @click="regist(999)">削除する</div>
            </div>
          </div>
        </div>

        <!-- display -->
        <div class="p-4" v-if="urlParam !== 'regist' && !flag.editNews">
          <div class="mb-3 fw-bold fs-lg">{{ newsData.title }}</div>
          <div><i class="me-2 mb-2"/>内容: {{ newsData.content }}</div>
          <div><i class="me-2 mb-2"/>掲載日: {{ publish_date.year }}年{{ publish_date.month }}月{{ publish_date.day }}日</div>
        </div>
      </div>

      <spacer :y="4"/>

    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Form,
  Loader,
  Calendar,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';
import moment from 'moment';

export default {
  name: 'news-detail',
  mixins: [cf],
  components: {
    Spacer,
    Form,
    Loader,
    Calendar,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
        editNews: false,
      },
      baseData: [
        {
          name: 'title',
          label: 'タイトル',
          required: true,
          type: 'text',
          value: '',
        },
        {
          name: 'content',
          label: '詳細',
          required: true,
          type: 'textarea',
          value: '',
        },
      ],
      publish_date: null,
      news: null,
    };
  },
  created() {
    this.getNews();
    if (this.urlParam === 'regist') {
      const today = moment(new Date());
      this.publish_date = {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      };
    }
  },
  computed: {
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    newsData() {
      return cf.pullOutValues(this.baseData);
    },
    readySubmit() {
      const base = this.baseData.every((item) => !item.required || (item.required && item.value));
      const date = moment(`${this.publish_date.year}-${this.publish_date.month}-${this.publish_date.day}`).format('YYYY-MM-DD');
      return base && date;
    },
  },
  watch: {
    urlParam() {
      if (this.$route.href.includes('news')) {
        this.getNews();
      }
    },
  },
  methods: {
    getNews() {
      if (this.urlParam === 'regist') {
        this.flag.editNews = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 2],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/news/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (res.detail) {
            this.news = res.detail;
            this.setValues();
          } else {
            alert('お知らせ情報が見つかりませんでした。');
            this.$router.push('/news/');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    setValues() {
      this.baseData.forEach((item) => {
        item.value = this.news[item.name];
      });
      const publishDate = moment(this.news.publish_date);
      this.publish_date = {
        year: publishDate.year(),
        month: publishDate.month() + 1,
        day: publishDate.date(),
      };
    },

    changeFormSubmitFlag(bool) {
      this.readySubmit = bool;
    },

    regist(flag) {
      if (flag === 999 && !confirm('お知らせを削除してよろしいですか？')) return;
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      let updateData = { flag };
      if ([1, 2].includes(flag)) {
        const data = cf.pullOutValues(this.baseData);
        data.publish_date = moment(`${this.publish_date.year}-${this.publish_date.month}-${this.publish_date.day}`).format('YYYY-MM-DD');

        updateData = { flag, ...data };
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.news.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/news/${endpoint}/`,
        data: updateData,
      })
        .then(() => {
          if (endpoint === 'create') {
            alert('お知らせ登録が完了しました。');
            this.$router.push('/news/');
          } else {
            alert(`お知らせを${flag === 999 ? '削除' : '更新'}しました。`);
            if (flag === 999) return this.$router.replace('/news/');
            this.flag.editNews = false;
          }
          this.getNews();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.required {
  color: red;
  font-size: 10px;
}
</style>
