<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-solid fa-user-tie me-3"/>顧客一覧</div>
    </div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <div class="row" v-if="!flag.isLoading.get">
        <ul class="col-9">
          <div v-if="!users?.length" class="mt-3">
              <p class="fs-6">※顧客が登録されていません。検索条件を変更するか、新規登録してください。</p>
          </div>
          <li
            v-for="(u, i) of users"
            :key="u.id">
            <router-link
              :to="`/customer/${u.id}/`"
              class="py-2 px-3 bdr-x bdr-t fc-black w-100 d-block"
              :class="[
                i === 0 ? 'rounded-top-2' : '',
                i === users.length - 1 ? 'bdr-b rounded-bottom-2' : '',
              ]">
              <div>
                <div class="d-flex align-items-center">
                  <i
                    v-if="u.username"
                    class="fs-5 fa-solid fa-user me-2"/>
                  <i
                    v-if="u.line_user_id && !u.username"
                    class="fs-3 fa-brands fa-line me-2"/>
                  <div
                    v-if="u.username"
                    class="me-2">{{ u.username }}</div>
                  <div
                    v-if="u.line_user_id && !u.username"
                    class="me-2">{{ u.line_username }}</div>
                  <div
                    v-if="u.username && u.line_user_id"
                    class="fs-xs fc-line-green">LINE連携済</div>
                </div>
                <div
                  v-if="u.email"
                  class="fs-xs fc-gray mt-2">{{ u.email }}</div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="col-3">
          <div class="card">
          <router-link
            to="/customer/regist/"
            class="btn btn-primary w-100">
            <i class="fa-regular fa-plus me-2"/>新規登録
          </router-link>
        </div>
        <spacer :y="2"/>
        <div class="card" v-if="isSuperUser(user)">
            <div class="card-header"><i class="fa-regular fa-magnifying-glass me-2"/>検索</div>
            <div class="card-body">
              <div>
                <label for="searchShop">店舗</label>
                <select
                  class="form-control form-control-sm"
                  name="shop"
                  id="shop"
                  :disabled="editId"
                  v-model="searchParams.shopId">
                  <option :value="0">選択してください</option>
                  <option
                    v-for="s in shops"
                    :key="s.id"
                    :value="s.id"
                    :selected="searchParams.shopId == s.id">{{ s.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'customers-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
      },
      shops: [],
      searchParams: {
        shopId: 0,
      },
      users: [],
    };
  },
  created() {
    this.getUsers();
    this.getShops();
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
    searchParams: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
  },
  methods: {
    getUsers() {
      if (!this.user.role) return;

      this.flag.isLoading.get = true;
      const params = {
        role: [1],
        flags: [1],
      };

      if (this.searchParams.shopId) params.shopId = this.searchParams.shopId;

      if (!this.isSuperUser(this.user)) {
        if (this.user.shops?.[0].id) params.shop_id = this.user.shops[0].id;
        else return;
      }

      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.users = res.list.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    getShops() {
      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">

</style>
