<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="fw-bold fs-lg"><i class="fa-solid fa-user me-3 mb-4"/>管理者一覧</div>
    <div class="row">
      <div class="col-9">
        <Loader :flag="flag.isLoading.get.users">
          <ul
            class="list-group list-group-checkable d-grid gap-2 border-0"
            v-if="!flag.isLoading.get.users">
            <div v-if="!adminUsers?.length" class="mt-3">
              <p class="fs-6">※管理者が登録されていません。新規登録してください。</p>
            </div>
            <li
              v-else
              v-for="admin of adminUsers" :key="admin"
              :class="[s.item, editId === admin.id ? s.edit : '', admin.flag === 10 ? 'bg-light' : '']"
              class="list-group-item rounded-3 py-3">
              <div
                v-if="editId !== admin.id"
                class="d-flex align-items-center justify-content-between">
                <div>{{ admin.username || '名前未設定' }}<span class="d-block small opacity-50">{{ admin.email }}</span></div>
                <div>
                  <button
                    v-if="admin.flag === 1"
                    @click="startEdit(admin)"
                    class="btn btn-outline-success btn-sm">変更</button>
                </div>
              </div>
              <div
                :class="s.form"
                v-else>
                <div>
                  <label for="">メールアドレス</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="updateData.email">
                </div>
                <div class="mt-3">
                  <label for="">名前</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="updateData.username">
                </div>
                <div class="mt-3">
                  <label for="">権限</label>
                  <select
                    class="form-control form-control-sm"
                    name="role"
                    id="role"
                    v-model="updateData.role">
                    <option
                      v-for="r in roles"
                      :key="r.name"
                      :value="r.role"
                      :selected="r.role === admin.role.role"
                      v-show="r.name !== 'developer' || user.role && user.role.role === 22">{{ r.label }}</option>
                  </select>
                </div>
                <div class="mt-3" v-if="[21, 22].includes(user.role.role)">
                  <label for="role">店舗</label>
                  <select
                    class="form-control form-control-sm"
                    name="shop"
                    id="shop"
                    v-model="updateData.shopId">
                    <option :value="0">選択してください</option>
                    <option
                      v-for="s in shops"
                      :key="s.id"
                      :value="s.id"
                      :selected="shopId == s.id">{{ s.name }}</option>
                  </select>
                </div>
                <div class="d-flex mt-3">
                  <button
                    class="btn btn-primary btn-sm"
                    :disabled="!updateData.email || !updateData.username || !updateData.shopId"
                    @click="regist">更新</button>
                  <button
                    @click="editId = null"
                    class="btn btn-outline-danger btn-sm ms-2">キャンセル</button>
                </div>
              </div>
            </li>
          </ul>
        </Loader>
      </div>
      <div class="col-3">
        <div class="card mb-4">
          <div class="card-header"><i class="fa-regular fa-magnifying-glass me-2"/>検索</div>
          <div class="card-body" :class="s.form">
            <div v-if="isSuperUser(user)" class="mb-3">
              <label for="searchShop">店舗</label>
              <select
                class="form-control form-control-sm"
                name="shop"
                id="shop"
                :disabled="editId"
                v-model="searchParams.shopId">
                <option :value="0">選択してください</option>
                <option
                  v-for="s in shops"
                  :key="s.id"
                  :value="s.id"
                  :selected="searchParams.shopId == s.id">{{ s.name }}</option>
              </select>
            </div>
            <div>
              <label for="">権限</label>
              <select
                class="form-control form-control-sm"
                name="role"
                id="role"
                v-model="searchParams.role">
                <option :value="0">選択してください</option>
                <option
                  v-for="r in adjustedRoles"
                  :key="r.name"
                  :value="r.role"
                  v-show="r.name !== 'developer' || user.role && user.role.role === 22">{{ r.label }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header"><i class="fa-regular fa-pen-to-square me-2"/>新規登録</div>
          <div class="card-body" :class="s.form">
            <div>
              <label for="email">メールアドレス</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="email"
                :disabled="editId">
            </div>
            <div class="mt-3">
              <label for="email">名前</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="username"
                :disabled="editId">
            </div>
            <div class="mt-3">
              <label for="role">権限</label>
              <select
                class="form-control form-control-sm"
                name="role"
                id="role"
                v-model="role"
                :disabled="editId">
                <option
                  v-for="r in adjustedRoles"
                  :key="r.name"
                  :value="r.role"
                  :selected="r.role === role"
                  v-show="r.name !== 'developer' || user.role && user.role.role === 22">{{ r.label }}</option>
              </select>
            </div>
            <div class="mt-3" v-if="isSuperUser(user)">
              <label for="role">店舗</label>
              <select
                class="form-control form-control-sm"
                name="shop"
                id="shop"
                v-model="shopId"
                :disabled="editId">
                <option :value="0">選択してください</option>
                <option
                  v-for="s in shops"
                  :key="s.id"
                  :value="s.id"
                  :selected="shopId == s.id">{{ s.name }}</option>
              </select>
            </div>
            <button
              :disabled="!email || !username || !shopId"
              @click="regist"
              class="btn btn-primary w-100 py-2 mt-3">登録</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import {
  Spacer,
  Loader,
} from '@/components/parts';

export default {
  name: 'users',
  mixins: [cf],
  components: {
    Loader,
    Spacer,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: {
            users: true,
            shops: true,
          },
        },
      },
      searchParams: {
        shopId: 0,
        role: 0,
      },
      email: null,
      username: null,
      role: 11,
      shopId: 0,
      updateData: {
        email: null,
        username: null,
        role: 11,
        shopId: 0,
      },
      editId: null,
      roles: [
        {
          name: 'shop-manager',
          label: '店舗管理者',
          role: 11,
        },
        {
          name: 'shop-editor',
          label: '店舗編集者',
          role: 12,
        },
        {
          name: 'glink',
          label: '特権管理者(GLINK)',
          role: 21,
        },
        {
          name: 'developer',
          label: '特権管理者(開発者)',
          role: 22,
        },
      ],
      adminUsers: [],
      shops: [],
    };
  },
  created() {
    this.get();
    this.getShops();
  },
  computed: {
    ...mapState(['helper', 'user']),
    adjustedRoles() {
      if (!this.user.role) return [];
      if (this.user.role.role === 22) return this.roles;
      if (this.user.role.role === 21) return this.roles.filter((role) => role.role !== 22);
      return this.roles.filter((role) => ![21, 22].includes(role.role));
    },
  },
  watch: {
    user: {
      handler() {
        this.get();
        this.getShops();
      },
      deep: true,
    },
    searchParams: {
      handler() {
        if (!this.editId) {
          this.get();
        }
      },
      deep: true,
    },
  },
  methods: {
    get() {
      if (!this.user.role) return;
      const params = {
        role: [11, 12, 21],
        flags: [1],
      };

      if (this.user.role.role === 22) params.role.push(22); // 開発の場合のみ、開発権限も取得

      if (!this.isSuperUser(this.user) && this.user.shops[0]) params.shopId = this.user.shops[0].id; // superuser以外は所属の店舗での絞り込みがデフォルト
      else if (!this.isSuperUser(this.user)) return alert('ログイン中のアカウントは店舗に紐づけられていないため閲覧することができません。管理者にお問合せください'); // superuserでなくshopの紐付けない場合（想定ではない）

      if (this.searchParams.shopId) params.shopId = this.searchParams.shopId;
      if (this.searchParams.role) params.role = [this.searchParams.role]; // roleが選択されている場合はparams上書き

      this.flag.isLoading.get.users = true;
      
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.adminUsers = res.list.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get.users = false;
        });
    },

    getShops() {
      if (!this.user.role) return;
      this.flag.isLoading.get.shops = true;

      // 管理者以外の場合店舗選択不可なのでgetする必要なし
      if (!this.isSuperUser(this.user)) {
        this.shopId = this.user.shops?.[0]?.id;
        if (!this.shopId) alert('ログイン中のアカウントは店舗に登録されていないため管理者の登録・更新はできません。店舗へ登録するには管理者にご連絡ください。');
        return;
      }

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get.shops = false;
        });
    },

    init() {
      this.email = null;
      this.role = 11;
      this.username = null;
      this.shopId = 0;
      this.editId = null;
      this.updateData.email = null;
      this.updateData.role = null;
      this.updateData.username = null;
      this.updateData.shopId = 0;
    },

    startEdit(user) {
      this.init();
      this.editId = user.id;
      this.updateData.email = user.email;
      this.updateData.username = user.username;
      this.updateData.role = user.role.role;
      this.updateData.shopId = user.shops[0]?.id || 0;
    },

    cancelEdit() {
      this.init();
    },

    regist() {
      const endpoint = this.editId ? 'update' : 'create';

      const data = {
        email: this.updateData.email || this.email,
        username: this.updateData.username || this.username,
        role: this.updateData.role || this.role,
        shopData: {
          shop_id: this.updateData.shopId || this.shopId,
          type: 11,
        },
      };

      if (endpoint === 'create') {
        data.flag = 10;
        data.sendEmail = 1;
        data.templateType = 2;
      }
      if (endpoint === 'update') data.id = this.editId;

      this.axios({
        method: 'POST',
        url: `/v1/user/${endpoint}`,
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.userID && endpoint === 'create') {
            alert('入力されたメールアドレスにパスワード設定用のメールを送信しました。');
            this.init();
          }
          if (endpoint === 'update') {
            alert('管理者情報を更新しました。');
            this.init();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.form {
  label {
    color: var(--label-secondary);
    font-size: 14px;
    font-weight: 700;
  }
}
.item {
  transition: all .5s;
  max-height: 80px;
  overflow: hidden;
  &.edit {
    max-height: 1000px;
  }
}
</style>

<style lang="scss" scoped>
.list-group-item {
  border-top-width: 1px !important;
}
</style>
