<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-building me-3"/>企業情報{{ urlParam === 'regist' ? '登録' : '編集'}}</div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <div class="bdr rounded-2" v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              v-if="flag.editCompany"
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!readySubmit"
              @click="regist(1)"><i class="fa-regular fa-download me-1"/>保存する</button>
            <button
              v-if="flag.editCompany && urlParam !== 'regist'"
              type="button"
              class="btn btn-outline-secondary btn-sm ms-2"
              @click="flag.editCompany = !flag.editCompany"><i class="fa-regular fa-x me-1"/>キャンセル</button>
            <button
              v-if="urlParam !== 'regist' && !flag.editCompany"
              type="button"
              class="btn btn-primary btn-sm"
              @click="flag.editCompany = true"><i class="fa-regular fa-pen-to-square me-2"/>編集する</button>
          </div>
        </div>
        <!-- edit -->
        <div class="px-4 py-3" v-if="flag.editCompany">
          <Form
            v-model:items="baseData"
            @changeSubmitFlag="changeFormSubmitFlag" />
          <div v-if="urlParam !== 'regist'">
            <spacer :y="4"/>
            <div class="bdr-t pt-3 d-flex justify-content-center">
              <div
                class="text-decoration-underline pointer fc-red fs-sm"
                @click="regist(999)">削除する</div>
            </div>
          </div>
        </div>

        <!-- display -->
        <div class="p-4" v-if="urlParam !== 'regist' && !flag.editCompany">
          <div class="mb-3 fw-bold fs-lg">{{ companyData.name }}</div>
          <div><i class="fa-solid fa-house me-2 mb-2"/>{{ adjustAddress(companyData.address) }}</div>
          <div><i class="fa-solid fa-phone me-2 mb-2"/>{{ companyData.tel }}</div>
          <div><i class="fa-regular fa-badge-check me-2 mb-2"/>古物商許可番号: {{ companyData.license_num }}</div>
          <div><i class="fa-solid fa-user me-2 mb-2"/>資格所有者名: {{ companyData.holder_name }}</div>
          <div><i class="fa-solid fa-users-medical me-2 mb-2"/>加盟団体名: {{ companyData.organizations }}</div>
        </div>
      </div>

      <spacer :y="4"/>

      <!-- shops -->
      <div v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between">
          <div class="fw-bold fs-lg"><i class="fa-regular fa-house me-3"/>店舗一覧</div>
          <router-link
            :to="`/shop/regist/?company_id=${urlParam}`">
            <button
              type="button"
              class="btn btn-primary btn-sm">新規店舗登録</button>
          </router-link>
        </div>
        <spacer :y="2"/>
        <ul v-if="company?.shops?.length">
          <li
            v-for="(shop, i) of company.shops"
            :key="shop.id">
            <router-link
              :to="`/shop/${shop.id}/`"
              class="py-3 px-3 bdr-x bdr-t fc-black w-100 d-block"
              :class="[
                i === 0 ? 'rounded-top-2' : '',
                i === company.shops.length - 1 ? 'bdr-b rounded-bottom-2' : '',
              ]">
              <div class="">{{ shop.name }}</div>
              <ul
                class="d-flex flex-wrap mt-2"
                v-if="shop.users.length">
                <li
                  class="px-2 py-1 fs-xs bg-gray rounded-2 me-2 d-flex align-items-center"
                  v-for="su in shop.users"
                  :key="su.id">
                  <i
                    :class="su.role.role === 12 ? 'fa-solid fa-user' : 'fa-solid fa-user-pen'"
                    class="me-2 fs-sm"/>
                  {{ su.username || su.line_username }}
                </li>
              </ul>
            </router-link>
          </li>
        </ul>
        <div
          v-else
          class="py-2 px-3 bdr rounded-2 mt-3">店舗の登録がありません。</div>
      </div>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Form,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'company-detail',
  mixins: [cf],
  components: {
    Spacer,
    Form,
    Loader,
  },
  data() {
    return {
      readySubmit: false,
      flag: {
        isLoading: {
          get: true,
        },
        editCompany: false,
      },
      baseData: [
        {
          name: 'name',
          label: '企業名',
          required: true,
          type: 'text',
          value: '',
        },
        {
          name: 'address',
          label: '所在地',
          required: true,
          requiredLabel: '番地まで必須入力',
          type: 'address',
          value: {
            zip: null,
            pref: null,
            town: null,
            city: null,
            address: null,
            building: null,
          },
        },
        {
          name: 'tel',
          label: '電話番号',
          required: true,
          type: 'text',
          value: '',
        },
        {
          name: 'business',
          label: '事業内容',
          required: false,
          type: 'text',
          value: '',
        },
        {
          name: 'employees_num',
          label: '従業員数',
          required: false,
          type: 'text',
          value: '',
        },
        {
          name: 'license_num',
          label: '古物商許可番号',
          required: false,
          type: 'text',
          value: '',
        },
        {
          name: 'holder_name',
          label: '資格所有者',
          required: false,
          type: 'text',
          value: '',
        },
        {
          name: 'organizations',
          label: '加盟団体',
          required: false,
          type: 'text',
          value: '',
        },
      ],
      company: null,
    };
  },
  created() {
    this.getCompany();
  },
  computed: {
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    companyData() {
      return cf.pullOutValues(this.baseData);
    },
  },
  watch: {
    urlParam() {
      if (this.$route.href.includes('company')) {
        this.getCompany();
      }
    },
  },
  methods: {
    getCompany() {
      if (this.urlParam === 'regist') {
        this.flag.editCompany = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/company/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (res.detail) {
            this.company = res.detail;
            this.setValues();
          } else {
            alert('企業情報が見つかりませんでした。');
            this.$router.push('/company/');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    setValues() {
      this.baseData.forEach((item) => {
        if (item.name === 'address') {
          Object.keys(item.value).forEach((addressItem) => {
            item.value[addressItem] = this.company[addressItem];
          });
        } else {
          item.value = this.company[item.name];
        }
      });
    },

    changeFormSubmitFlag(bool) {
      this.readySubmit = bool;
    },

    regist(flag) {
      if (flag === 999 && !confirm('企業情報を削除してよろしいですか？')) return;
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      let updateData = { flag };
      if (flag === 1) {
        const data = cf.pullOutValues(this.baseData);
        const address = data.address;
        delete data.address;

        updateData = { flag: 1, ...data, ...address };
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.company.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/company/${endpoint}/`,
        data: updateData,
      })
        .then((response) => {
          const res = response.data;
          if (endpoint === 'create') {
            alert('企業情報登録が完了しました。');
            this.$router.replace(`/company/${res.companyId}`);
          } else {
            alert(`企業情報を${flag === 999 ? '削除' : '更新'}しました。`);
            if (flag === 999) return this.$router.replace('/company/');
            this.getCompany();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">

</style>
