<template>
  <div>
    <ul :class="s.category">
      <li
        v-for="(ctg, i) in options"
        :key="i">
        <div class="fw-bold pb-2">{{ ctg.label }}</div>
        <ul :class="s.details">
          <li
            v-for="(opt, j) in ctg.options"
            :key="j"
            :class="values?.length && values[i].includes(opt.name) ? s.active : ''">
            <label
              class="w-100 pointer"
              :for="opt.name">
              <div class="d-flex align-items-center">
                <input
                  class="me-2"
                  type="checkbox"
                  :id="opt.name"
                  :checked="values[i].includes(opt.name)"
                  @change="changeValue(i, opt.name)">
                <span class="fw-bold fs-sm">{{ opt.label }}</span>
              </div>
              <div
                v-if="opt.detail && values[i].includes(opt.name)">
                <!-- １つ選ぶタイプ -->
                <select
                  v-if="opt.detailType === 'select-one'"
                  class="form-control form-control-sm mt-2"
                  name="" id=""
                  @change="changeDetailValue(i, opt.name, $event)">
                  <option :value="''">選択してください</option>
                  <option
                    v-for="(value, key) in opt.detail"
                    :key="key"
                    :value="key"
                    :selected="values[i].includes(key)">{{ value }}</option>
                </select>

                <!-- 複数選ぶタイプ -->
                <ul
                  v-if="opt.detailType === 'select-multiple'"
                  class="mt-2">
                  <li
                    v-for="(val, key) in opt.detail"
                    :key="key"
                    class="d-flex align-items-center pb-1">
                    <input
                      type="checkbox"
                      :id="`${opt.name}_${key}`"
                      :checked="values[i].includes(key)"
                      @change="changeValue(i, key)">
                    <label
                      class="ms-2 fs-xs pointer"
                      :for="`${opt.name}_${key}`">{{ val }}</label>
                  </li>
                </ul>
              </div>
            </label>

          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'option-form',
  mixins: [cf],
  props: ['initValue'],
  data() {
    return {
      values: [
        [], // 装備
        [], // 外装・内装
        [], // 運転支援
        [], // 安全装備エリア
      ],
    };
  },
  created() {
    if (this.initValue) {
      this.values = JSON.parse(this.initValue);
    }
  },
  computed: {
    ...mapState([['helper']]),
    options() {
      return this.helper.master.labels ? this.helper.master.labels.cars.options.front : [];
    },
  },
  watch: {
    values: {
      handler() {
        if (this.$parent.$parent.values) this.$parent.$parent.values.options = JSON.stringify(this.values);
      },
      deep: true,
    },
    initValue() {
      if (this.initValue) {
        this.values = JSON.parse(this.initValue);
      }
    },
  },
  methods: {
    changeValue(ctgIdx, name) {
      const isAdd = !this.values[ctgIdx].includes(name);
      if (isAdd) this.values[ctgIdx].push(name); // add
      else this.values[ctgIdx] = this.values[ctgIdx].filter((n) => n !== name); // remove
    },
    changeDetailValue(ctgIdx, optionName, e) {
      if (!this.options.length) return;
      const detailName = e.target.value;
      const target = this.options[ctgIdx].options.find((opt) => opt.name === optionName); // 該当のoptionを抽出
      this.values[ctgIdx] = this.values[ctgIdx].filter((name) => !target.detail[name]); // 該当optionのdetailに含まれるものがvalues内にあったら除外
      if (detailName) this.values[ctgIdx].push(detailName); // detailNameがあれば追加
    },
  },
};
</script>

<style lang="scss" module="s">
$bdr: var(--border-gray-opacity-1);

.category {
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

.details {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid $bdr;
  border-top: 1px solid $bdr;
  > li {
    width: 25%;
    border-bottom: 1px solid $bdr;
    border-right: 1px solid $bdr;
    transition: all .3s;
    color: var(--label-secondary);
    > * {
      padding: 12px;
    }

    &.active {
      color: var(--label-primary);
      background-color: var(--bg-gray);
    }

    label {
      height: 100%;
    }
  }
}
</style>
