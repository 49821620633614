<template>
  <div>
    <Form v-model:items="formItems.baseData"/>
    <div>
      <div class="mt-3 mb-2">画像</div>
      <ImageForm
        :isModal="true"
        v-model:initMedias="formItems.images"
        @changeFlag="changeImageFlag"
        @changeData="updateImages"
        @changeModalData="updateImages" />
    </div>
    <div v-if="urlParam !== 'regist'">
      <spacer :y="4"/>
      <div class="bdr-t pt-3 d-flex justify-content-center">
        <div
          class="text-decoration-underline pointer fc-red fs-sm"
          @click="regist(999)">削除する</div>
      </div>
    </div>
    <div v-if="showRegistBtn">
      <spacer :y="4"/>
      <button
        type="button"
        class="btn btn-primary btn-sm"
        :disabled="!readySubmit"
        @click="regist(1)"><i class="fa-regular fa-circle-plus me-2"/>登録する</button>
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm ms-3"
        @click="hideModal"><i class="fa-regular fa-x me-2"/>キャンセル</button>
    </div>
  </div>
</template>

<script>
import {
  Form,
  ImageForm,
  Spacer,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';
import { mapState } from 'vuex';

export default {
  name: 'VehicleForm',
  mixins: [cf],
  components: {
    Form,
    ImageForm,
    Spacer,
  },
  props: {
    vehicle: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    showRegistBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      flag: {
        beforeUploadImages: false,
      },
      formItems: this.formData,
    };
  },
  created() {
    this.$emit('readySubmit', this.readySubmit);
    this.getBrands();
    if (this.modal?.contents?.data?.brand_id) {
      this.formItems.baseData[1].value = this.modal.contents.data.brand_id;
    }
  },
  watch: {
    formItems: {
      handler() {
        this.$emit('input', this.formItems);
      },
      deep: true,
    },
    readySubmit: {
      handler() {
        this.$emit('readySubmit', this.readySubmit);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['helper', 'modal']),
    readySubmit() {
      return this.formItems.baseData.every((item) => !item.required || (item.required && item.value)) && !this.flag.beforeUploadImages;
    },
    urlParam() {
      if (!this.isVehiclePage) return 'regist';
      return Number(this.$route.params.id) || 'regist';
    },
    isVehiclePage() {
      return this.$route.href.includes('vehicle');
    },
  },
  methods: {
    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },
    changeImageFlag(value) {
      this.flag.beforeUploadImages = value;
    },
    updateImages(images) {
      this.formItems.images = images;
    },
    getBrands() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
          this.formItems.baseData[1].options = this.brands;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    regist(flag) {
      if (flag === 999 && !confirm('車両情報を削除してよろしいですか？')) return;
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const imageIds = this.formItems.images.map((image) => image.id);

      let updateData = { flag };
      if (flag === 1) {
        const data = cf.pullOutValues(this.formItems.baseData);
        updateData = { flag, ...data, images: imageIds };
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.vehicle.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/vehicle/${endpoint}/`,
        data: updateData,
      })
        .then((response) => {
          const res = response.data;
          if (endpoint === 'create') {
            alert('車両情報登録が完了しました。');
            if (this.isVehiclePage) this.$router.push(`/brand/${updateData.brand_id}`);
            else this.$emit('hideModal', res.vehicleId);
          } else {
            alert(`車両情報を${flag === 999 ? '削除' : '更新'}しました。`);
            if (flag === 999) {
              this.$router.push('/brand/');
            } else {
              this.$router.push(`/brand/${updateData.brand_id}`);
            }
          }
          this.$emit('editVehicle', false);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">

</style>
