<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-solid fa-user-tie me-3"/>顧客{{ urlParam === 'regist' ? '登録' : '詳細'}}</div>
    </div>

    <spacer :y="2"/>

    <Loader :flag="flag.isLoading.get">
      <!-- 基本情報 -->
      <div class="bdr rounded-2">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              v-if="flag.edit"
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!readySubmit"
              @click="regist"><i class="fa-regular fa-download me-1"/>保存する</button>
            <button
              v-if="flag.edit && urlParam !== 'regist'"
              type="button"
              class="btn btn-outline-secondary btn-sm ms-2"
              @click="flag.edit = !flag.edit"><i class="fa-regular fa-x me-1"/>キャンセル</button>
            <button
              v-if="urlParam !== 'regist' && !flag.edit"
              type="button"
              class="btn btn-primary btn-sm"
              @click="flag.edit = true"><i class="fa-regular fa-pen-to-square me-2"/>編集する</button>
          </div>
        </div>

        <!-- edit -->
        <div class="px-4 py-3" v-if="flag.edit">
          <Form v-model:items="baseData"/>
          <FormParts
            v-if="urlParam === 'regist' && isInputEmail"
            class="flex-1"
            :type="'checkbox'"
            :name="'sendEmailFlag'"
            :initValue="sendEmailFlag"
            :label="'パスワード設定メールの送信'"/>
          <spacer :y="1"/>
          <div
            v-if="isSuperUser(user)"
            class="fs-xs fc-gray fw-bold">
            <div>
              登録店舗
              <span :class="s.required">※必須項目</span>
            </div>
            <select
              name="shop" id="shop"
              class="mt-1 form-control form-control-sm"
              v-model="shopId">
              <option :value="0">選択してください</option>
              <option
                v-for="s in shops"
                :key="s.id"
                :value="s.id">{{ s.name }}</option>
            </select>
          </div>
        </div>

        <!-- display -->
        <div class="p-4" v-if="!flag.edit && customer">
          <div class="mb-2 fw-bold fs-lg">{{ customer.username || customer.line_username }}</div>
          <div v-if="customer.shops[0]" class="mb-2"><i class="fa-regular fa-house me-2"/>{{ customer.shops[0].name }}</div>
          <div><i class="fa-regular fa-envelope me-2 mb-2"/>メールアドレス: {{ customer.email || '未登録' }}</div>
          <div><i class="fa-brands fa-line me-2 mb-2 mb-2"/>LINEユーザーネーム: {{ customer.line_username || '未登録' }}</div>
          <div><i class="fa-regular fa-phone me-2"/>電話番号: {{ customer.tel || '未登録' }}</div>
        </div>
      </div>

      <spacer :y="3"/>
      <!-- 紐付け -->
      <div class="bdr rounded-2" v-if="customer && !customer.line_user_id">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">LINEユーザー紐付け</div>
        </div>
        <div class="px-4 py-3">
          <select
            class="form-control form-control-sm"
            :disabled="false"
            v-model="selectedOnlyLineUser">
            <option :value="0">選択してください</option>
            <option
              v-for="user in onlyLineUsers"
              :key="user.id"
              :value="user.id"
              :selected="user.id === selectedOnlyLineUser">{{ user.line_username }}</option>
          </select>
          <spacer :y="2"/>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            :disabled="!selectedOnlyLineUser"
            @click="connectLineUser"><i class="fa-regular fa-download me-1"/>紐付け実行</button>
        </div>
      </div>
      <spacer :y="3" v-if="customer && !customer.line_user_id"/>
      <!-- sales情報 -->
      <div class="bdr rounded-2" v-if="customer && customer.sales?.length">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
          <div class="fw-bold">販売情報</div>
        </div>
        <div class="px-2 py-2">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              v-for="sale in customer.sales"
              :key="sale.id">
              <router-link
                :to="`/sales/${sale.id}`"
                class="fw-bold">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="fw-bold">{{ sale?.salesCar?.brand.label }} {{ sale?.salesCar?.vehicle.name }}</div>
                  <div class="fw-normal fs-md fc-gray">{{ formatTimestamp(sale?.purchase_date, 'YYYY/MM/DD') }}</div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- そのほかの情報（sales情報など） -->

    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  Form,
  FormParts,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'customers-detail',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Form,
    FormParts,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: false,
        },
        edit: false,
      },
      customer: null,
      selectedOnlyLineUser: 0,
      onlyLineUsers: [],
      sendEmailFlag: 0,
      customerEmailOptions: [
        {
          name: 'customer',
          label: '顧客用テンプレートを使用',
          value: 1,
        },
        {
          name: 'admin',
          label: '管理者用テンプレートを使用',
          value: 0,
        },
      ],
      baseData: [
        {
          name: 'username',
          label: 'ユーザーネーム',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'tel',
          label: '電話番号',
          type: 'text',
          required: true,
          value: '',
        },
        {
          name: 'email',
          label: 'メールアドレス',
          type: 'text',
          required: false,
          value: '',
        },
      ],
      shops: [],
      shopId: 0,
    };
  },
  created() {
    this.getUser();
    this.getShops();
  },
  computed: {
    ...mapState(['user']),
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    readySubmit() {
      const base = this.baseData.every((item) => !item.required || (item.required && item.value));
      const shop = this.shopId;
      return base && shop;
    },
    isInputEmail() {
      const email = this.baseData.find((item) => item.name === 'email');
      return Boolean(email.value);
    },
  },
  watch: {
    urlParam() {
      this.getUser();
    },
    user: {
      handler() {
        this.getShops();
      },
      deep: true,
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    getUser() {
      if (this.urlParam === 'regist') {
        this.flag.edit = true;
        this.flag.isLoading.get = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        role: [1, 2, 22],
        flags: [1, 10],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.customer = res.detail;
          this.shopId = this.customer?.shops[0]?.id || 0;
          this.setValues();
          if (this.customer && !this.customer.line_user_id && this.customer.shops[0]) {
            this.getOnlyLineUsers();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getOnlyLineUsers() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1],
        onlyLineUser: 1,
      };

      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          const botId = this.customer.shops[0].line_bot_id;
          this.onlyLineUsers = res.list.data.filter((user) => {
            let friends;
            try {
              friends = JSON.parse(user.line_friend_accounts);
            } catch {
              friends = [];
            }
            return Boolean(friends.includes(botId));
          });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    getShops() {
      if (!this.isSuperUser(this.user)) {
        this.shopId = this.user.shops?.[0]?.id || 0;
        return;
      }
      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    setValues() {
      this.baseData.forEach((item) => {
        item.value = this.customer?.[item.name];
      });
    },

    changeValue(name, value) {
      this[name] = value;
    },

    regist() {
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const data = cf.pullOutValues(this.baseData);
      const updateData = {
        ...data,
        role: 1,
      };

      // 登録時のみのプロパティ
      if (endpoint === 'create') {
        updateData.sendEmail = this.sendEmailFlag;
        updateData.templateType = 3;
        updateData.flag = this.sendEmailFlag ? 10 : 1;
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        updateData.id = this.customer.id;
      }

      if (this.shopId) {
        updateData.shopData = {
          shop_id: this.shopId,
          type: 1,
        };
      }

      this.axios({
        method: 'POST',
        url: `/v1/user/${endpoint}/`,
        data: updateData,
      })
        .then((response) => {
          const res = response.data;
          if (res.exists) {
            alert('入力されたメールアドレスはすでに登録されています。');
            this.$router.replace(`/customer/${res.userID}`);
          } else if (endpoint === 'create') {
            alert('顧客情報登録が完了しました。');
            this.$router.replace(`/customer/${res.userID}`);
          } else {
            alert('顧客情報を更新しました。');
          }
          this.getUser();
          this.flag.edit = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    connectLineUser() {
      this.showLoading();
      const data = {
        id: this.customer.id,
        lineUserId: this.selectedOnlyLineUser,
      };
      this.axios({
        method: 'POST',
        url: '/v1/user/connectLineUser/',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.connected) {
            alert('LINEユーザーとの紐付けが完了しました。');
          }
          this.getUser();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.required {
  color: red;
  font-size: 10px;
}
</style>
