<template>
  <div class="container-lg">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-file-contract me-3"/>販売情報一覧</div>
    </div>

    <spacer :y="2"/>

    <div class="d-flex align-items-start" v-if="helper.master.labels">
      <Loader class="flex-1 me-3" :flag="flag.isLoading.get">
        <!-- 一覧 -->
        <div v-if="!flag.isLoading.get">
          <p v-if="!sales.length">※販売情報が存在しません。新規登録してください。</p>
          <ul class="list bdr rounded-2" v-else>
            <li>
              <div class="fs-sm fw-bold text-center">
                <div>車両写真</div>
                <div>車両基本情報</div>
                <div>顧客情報</div>
                <div>購入日</div>
              </div>
            </li>
            <li
              v-for="s of sales" :key="s">
              <router-link :to="`/sales/${s.id}/`">
                <div>
                  <div
                    class="img"
                    :style="`background-image: url(${s.salesCar.images?.length ? s.salesCar.images[0].url : 'https://placehold.jp/150x150.png'}); `"/>
                </div>
                <div>
                  <div class="fw-bold">{{ s.salesCar.vehicle?.name || '車両名なし' }}</div>
                  <div class="fs-xs fw-bold fc-gray">{{ s.salesCar.brand?.label }}</div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div>{{ s.user.username || s.user.line_user_name }}</div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column fs-sm">
                  <div
                    class="fs-xs mt-2 bg-gray px-2"
                    v-if="isSuperUser(user)">{{ s.shops?.[0]?.name }}</div>
                  <spacer :y="1"/>
                  <div>購入日：{{ formatTimestamp(s.purchase_date, 'YYYY/MM/DD') }}</div>
                </div>
              </router-link>
            </li>
          </ul>
          <Pagination
            class="mt-4"
            :path="'/sales/'"
            :page="page"
            :pageMax="pageMax"
            v-if="pageMax !== 1 && !flag.isLoading.get" />
        </div>
      </Loader>

      <!-- 検索 -->
      <div class="card w-25">
        <div class="card-header"><i class="fa-regular fa-magnifying-glass me-2"/>検索</div>
        <div class="card-body">
          <!-- フリーワード -->
          <div class="mb-3">
            <div class="fs-xs fc-gray fw-bold">フリーワード</div>
            <form class="input-group" @submit.prevent="getSales()">
              <input
                class="form-control form-control-sm"
                type="text"
                v-model="keyword">
              <button
                type="submit"
                class="btn btn-primary btn-sm"><i class="fa-regular fa-magnifying-glass"/></button>
            </form>
          </div>
          <!-- 店舗（superuserのみ） -->
          <div
            class="mb-3"
            v-if="user.role && isSuperUser(user)">
            <div class="fs-xs fc-gray fw-bold">店舗</div>
            <select
              name="shop"
              id="shop"
              v-model="selectedShopId"
              class="form-control form-control-sm">
              <option :value="0">選択してください</option>
              <option
                v-for="shop in shops"
                :key="shop.id"
                :value="shop.id">{{ shop.name }}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  Pagination,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'sales-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Pagination,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      sales: [],
      shops: [],
      selectedShopId: 0,
      keyword: '',
      page: 1,
      pageMax: 1,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.page) {
      this.page = Number(query.page);
    } else {
      // queryにpageがなければ追加してreplace
      this.$router.replace('?page=1');
    }
    this.getSales();
    this.getShops();
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      }
      this.getSales();
    },
    selectedShopId() {
      this.getSales();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    getSales() {
      this.flag.isLoading.get = false;
      const params = {
        page: this.page,
        limit: 10,
        shopId: this.selectedShopId,
      };

      this.axios({
        method: 'GET',
        url: '/v1/sale/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.sales = res.sales.data;
          this.pageMax = res.sales.meta.last_page;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },
    getShops() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/shop/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.shops = res.shops.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  overflow: hidden;
  > li {
    transition: all .3s;
    &:hover {
      background-color: var(--bg-gray-secondary);
    }
    &:first-child {
      background-color: var(--bg-gray);
    }
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray);
    }

    > * {
      display: flex;
      color: var(--label-primary);
      > * {
        padding: 12px;
        &:not(:first-child) {
          border-left: 1px solid var(--border-gray);
        }
      }

      > :nth-child(1) { // 写真
        width: 150px;
        > * {
          width: 100%;
          padding-top: 100%;
          background-size: contain;
          background-position: center;
        }
      }
      > :nth-child(2) { // 基本情報
        flex: 1;
      }
      > :nth-child(3) { // 価格
        width: 150px;
      }
      > :nth-child(4) { // 登録・更新日
        width: 200px;
      }
    }
  }
}
.tag {
  font-weight: 700;
  padding: 4px 9px;
  &.danger {
    border: 1px solid #dc3545;
    color: #dc3545;
  }
  &.gray {
    border: 1px solid gray;
    color: gray;
  }
  &.green {
    border: 1px solid #198754;
    color: #198754;
  }
  &.primary {
    border: 1px solid #0d6efd;
    color: #0d6efd;
  }
}
.img {
  width: 100%;
  padding-top: 75%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
