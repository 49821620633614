<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-building me-3"/>企業一覧</div>
      <div>
        <router-link
          :to="'/company/regist'">
          <button
            type="button"
            class="btn btn-primary btn-sm"><i class="fa-regular fa-plus me-2"/>新規登録</button>
        </router-link>
      </div>
    </div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <ul v-if="!flag.isLoading.get">
        <li
          v-for="(company, index) of companies"
          :key="company.id">
          <router-link
            :to="`/company/${company.id}/`"
            class="py-2 px-3 bdr-x bdr-t fc-black"
            :class="[
              index === 0 ? 'rounded-top-2' : '',
              index === companies.length - 1 ? 'bdr-b rounded-bottom-2' : '',
            ]">
            <div class="fw-bold fc-black">{{ company.name }}</div>
            <div class="fc-gray fs-xs mt-2">{{ adjustAddress(company) }}</div>
          </router-link>
        </li>
      </ul>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'company-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      companies: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 10],
      };

      this.axios({
        method: 'GET',
        url: '/v1/company/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.companies = res.companies.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    > * {
      display: block;
    }
  }
}
</style>
