<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-newspaper me-3"/>お知らせ一覧</div>
      <div>
        <router-link
          :to="'/news/regist'">
          <button
            type="button"
            class="btn btn-primary btn-sm"><i class="fa-regular fa-plus me-2"/>新規登録</button>
        </router-link>
      </div>
    </div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <ul v-if="!flag.isLoading.get && news.length">
        <li
          v-for="(item, index) of news"
          :key="item.id">
          <router-link
            :to="`/news/${item.id}/`"
            class="py-2 px-3 bdr-x bdr-t fc-black d-flex align-items-center"
            :class="[
              index === 0 ? 'rounded-top-2' : '',
              index === news.length - 1 ? 'bdr-b rounded-bottom-2' : '',
            ]">
            <div
              class="d-flex align-items-center flex-column fs-sm flex-shrink-0"
              :class="flagData[item.flag].class">{{ flagData[item.flag].label }}</div>
            <spacer :x="2"/>
            <div>
              <div class="fw-bold fc-black ellipsis">{{ item.title }}</div>
              <div class="fc-gray fs-xs ellipsis">{{ formatTimestamp(item.publish_date, 'YYYY/MM/DD') }}</div>
            </div>
          </router-link>
        </li>
      </ul>
      <Pagination
        class="mt-4"
        :path="'/news/'"
        :page="page"
        :pageMax="pageMax"
        v-if="pageMax !== 1 && !flag.isLoading.get" />
      <p v-if="!news.length">お知らせが登録されていません。登録してください。</p>
    </Loader>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
  Pagination,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'news-list',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Pagination,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      flagData: {
        1: {
          class: 'tag primary',
          label: '公開',
        },
        2: {
          class: 'tag gray',
          label: '下書き',
        },
      },
      news: [],
      page: 1,
      pageMax: 1,
    };
  },
  created() {
    this.getList();
  },
  watch: {
    $route: {
      handler() {
        const query = this.$route.query;
        if (query) {
          this.page = Number(query.page) || 1;
        }
        // クエリにページがない場合は追加してreplace
        if (this.$route.path === '/news/' && !query.page) this.$router.replace(`/news/?page=${this.page}`);
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    getList() {
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 2],
        page: this.page,
        limit: 10,
      };

      this.axios({
        method: 'GET',
        url: '/v1/news/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.news = res.news.data;
          this.pageMax = res.news.meta.last_page;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    > * {
      display: block;
    }
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag {
  font-weight: 700;
  padding: 4px 9px;
  &.danger {
    border: 1px solid #dc3545;
    color: #dc3545;
  }
  &.gray {
    border: 1px solid gray;
    color: gray;
  }
  &.green {
    border: 1px solid #198754;
    color: #198754;
  }
  &.primary {
    border: 1px solid #0d6efd;
    color: #0d6efd;
  }
}
</style>
