<template>
  <div class="regist-brand-vehicle">
    <p :class="s.title">{{data.name === 'brand' ? 'ブランド' : '車種'}}登録</p>
    <BrandForm
      v-if="data.name === 'brand'"
      :showRegistBtn="true"
      v-model:formData="brandData"
      @hideModal="hideModal"/>
    <VehicleForm
      v-if="data.name === 'vehicle'"
      :showRegistBtn="true"
      v-model:formData="vehicleData"
      @hideModal="hideModal"/>
  </div>
</template>

<script>
import {
  BrandForm,
  VehicleForm,
} from '@/components/parts';

export default {
  name: 'regist-brand-vehicle',
  components: {
    BrandForm,
    VehicleForm,
  },
  props: ['data'],
  data() {
    return {
      brandData: {
        baseData: [
          {
            name: 'label',
            label: 'ラベル',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'name',
            label: '英字表記',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'country',
            label: '国',
            type: 'select',
            options: [],
            required: true,
            value: 0,
          },
          {
            name: 'description',
            label: '詳細',
            type: 'textarea',
            size: 'half',
            required: false,
            value: '',
          },
        ],
        images: [],
      },
      vehicleData: {
        baseData: [
          {
            name: 'name',
            label: '車名',
            type: 'text',
            required: true,
            value: '',
          },
          {
            name: 'brand_id',
            label: 'ブランド',
            type: 'select',
            options: [],
            required: true,
            value: 0,
          },
          {
            name: 'feature',
            label: '特徴',
            type: 'textarea',
            size: 'half',
            required: true,
            value: '',
          },
          {
            name: 'description',
            label: '詳細',
            type: 'textarea',
            size: 'half',
            required: true,
            value: '',
          },
        ],
        images: [],
      },
    };
  },
  methods: {
    hideModal(registedId) {
      const payload = {
        type: 'update',
        get: this.data.name,
        id: registedId,
      };
      this.$store.dispatch('modal/contents/hideModal', payload, { root: true });
    },
  },
};
</script>

<style lang="scss" module="s">
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>
