<template>
  <div>
    <form action="">
      <ul>
        <li
          class="mb-3"
          v-for="item in formItems" :key="item.name">
          <label
            class="fs-xs fw-bold fc-gray"
            for="">
            {{ item.label }}
            <span
              :class="s.required"
              v-if="item.required"> ※{{ item.requiredLabel || '必須項目'}}</span>
          </label>

          <!-- formはtypeで出しわけ -->

          <!-- text -->
          <input
            v-if="item.type === 'text'"
            class="form-control form-control-sm"
            type="text"
            v-model="item.value">

          <!-- number -->
          <div v-if="item.type === 'number'" class="d-flex">
            <input
              class="form-control form-control-sm"
              type="number"
              v-model="item.value">
            <span
              v-if="item.suffix"
              :class="s.suffix"
              class="ms-3 d-flex align-items-center">{{ item.suffix }}</span>
          </div>

          <!-- textarea -->
          <textarea
            v-if="item.type === 'textarea'"
            class="form-control form-control-sm"
            :rows="item.size === 'half' ? 5 : 10"
            v-model="item.value" />

          <!-- select -->
          <div v-if="item.type === 'select'" :class="s.select_wrap">
            <select
              class="form-control form-control-sm"
              :name="item.name"
              :id="item.name"
              :disabled="!item.options.length"
              v-model="item.value">
              <option :value="0">選択してください</option>
              <option
                v-for="opt in item.options"
                :key="opt.id || opt.value"
                :value="opt.id || opt.value"
                :selected="opt.id ? opt.id === item.value : opt.value === item.value">{{ opt.label || opt.name }}</option>
            </select>
          </div>

          <!-- address -->
          <div
            class="pt-3 pb-2 px-2 bdr rounded-2"
            v-if="item.type === 'address'">
            <AddressForm
              :value="item.value"
              @changeAddressData="updateAddressData"
            />
          </div>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import AddressForm from '@/components/parts/AddressForm.vue';

export default {
  name: 'form',
  components: {
    AddressForm,
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      formItems: this.items,
    };
  },
  created() {
    this.$emit('changeSubmitFlag', this.readySubmit);
  },
  computed: {
    readySubmit() {
      return this.formItems.every((item) => !item.required || (item.required && item.value)) && this.readyAddress;
    },
    readyAddress() {
      const address = this.formItems.find((item) => item.type === 'address');
      if (address && address.value) {
        const targetColumns = ['zip', 'pref', 'city', 'town', 'address'];
        return targetColumns.every((column) => address.value[column]);
      }
      return true;
    },
  },
  watch: {
    items: {
      handler() {
        this.$emit('input', this.formItems);
      },
      deep: true,
    },
    readySubmit() {
      this.$emit('changeSubmitFlag', this.readySubmit);
    },
  },
  methods: {
    updateAddressData(value) {
      const addrIdx = this.formItems.findIndex((item) => item.type === 'address');
      this.formItems[addrIdx].value = value;
    },
  },
};
</script>

<style lang="scss" module="s">
.required {
  color: red;
  font-size: 10px;
}

.label_input {
  max-width: 200px;
}
.type_select {
  max-width: 300px;
}
.suffix {
  white-space: nowrap;
}

.select_wrap {
  position: relative;
  select {
    padding-right: 24px;
  }
  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-color: transparent var(--label-secondary) var(--label-secondary) transparent ;
  }
}
</style>
