<template>
  <div class="container">
    <div class="fw-bold fs-lg"><i class="fa-regular fa-file-contract me-3"/>販売情報{{ urlParam === 'regist' ? '登録' : '詳細'}}</div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <div v-if="!flag.isLoading.get">
        <!-- registでクエリパラメータがない、もしくは正しくない時 -->
        <div v-if="flag.invalidUrl">
          <div>URLが正しくありません。販売車両一覧または販売車両詳細ページのリンクよりアクセスしてください。</div>
          <spacer :y="2"/>
          <router-link
            to="/sales-car/">
            <button class="btn btn-primary">販売車両一覧</button>
          </router-link>
        </div>

        <div v-if="!flag.invalidUrl">
          <!-- 車両情報 -->
          <div class="bdr rounded-2">
            <div class=" bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
              <div class="fw-bold">販売車両情報</div>
            </div>
            <div class="p-4">
              <div
                class="mb-2 fs-xs"
                v-if="urlParam !== 'regist'">販売日：{{ formatTimestamp(sales.purchase_date, 'YYYY/MM/DD') }}</div>
              <div class="mb-2 fw-bold">{{ carData.vehicle.name }}</div>
              <div class="fs-xs">{{ carData.brand.label }}</div>
              <div
                v-if="carData.inspection_type === 1"
                class="fs-xs mt-2">次回車検：{{ carData.inspection_year }}年 {{ carData.inspection_month }}月</div>
              <div class="fs-xs mt-2">オイル交換目安：{{ oilChangeTime }}</div>
            </div>
          </div>

          <spacer :y="4"/>

          <!-- 購入者情報 -->
          <div class="bdr rounded-2">
            <div class=" bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
              <div class="fw-bold">購入者{{ urlParam === 'regist' ? '選択' : '詳細' }}</div>
            </div>
            <!-- 詳細 -->
            <div v-if="urlParam !== 'regist'" class="p-4">
              <div class="mb-2 fw-bold">購入者名: {{ sales.user.username || sales.user.line_username }}</div>
              <div class="fs-xs">メールアドレス: {{ sales.user.email || '設定無し' }}</div>
              <div class="fs-xs">電話番号: {{ sales.user.tel || '設定無し' }}</div>
            </div>
            <!-- 登録 -->
            <div v-else class="p-4">
              <!-- 選択中のuser -->
              <div
                v-if="!selectedUserId"
                class="fs-xs">購入者を選択してください。</div>
              <div class="bg-gray-secondary p-3 rounded-2" v-if="selectedUserId">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="fw-bold"><i class="fa-solid fa-user me-2"/>{{ selectedUser.username || selectedUser.line_user_name }}</div>
                    <div
                      class="fs-xs mt-2"
                      v-if="selectedUser.email"><i class="fa-regular fa-envelope me-2"/>{{ selectedUser.email }}</div>
                  </div>
                  <div class="w-25">
                    <div class="fw-bold"><i class="fa-solid fa-calendar-days me-2"/>販売日：{{ purchase_date?.year }}/{{ purchase_date?.month }}/{{ purchase_date?.day }}</div>
                    <div
                      class="mt-3">
                      <Calendar
                        v-model:pickedDate="purchase_date"/>
                    </div>
                  </div>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                  <button
                    class="btn btn-primary btn-sm"
                    type=""
                    @click="regist">購入者を登録する</button>
                  <button
                    class="btn btn-outline-danger btn-sm ms-2"
                    type=""
                    @click="selectedUserId = null">選択し直す</button>
                </div>
              </div>

              <!-- 検索用フォーム -->
              <div v-if="!selectedUserId">
                <form class="input-group mt-3" v-on:submit.prevent="getUsers()">
                  <input
                    type="text"
                    placeholder="キーワードで検索"
                    v-model="keyword"
                    class="form-control">
                  <button
                    class="btn btn-primary"
                    type="button"><i class="fa-regular fa-magnifying-glass"/></button>
                </form>
                <!-- 一覧 -->
                <div
                  class="mt-3"
                  v-if="!users.length">該当するユーザーはいませんでした。検索条件をご確認ください。</div>
                <ul
                  v-if="users.length"
                  class="list-group mt-3">
                  <li
                    v-for="u in users"
                    :key="u.id"
                    class="list-group-item pointer"
                    @click="selectedUserId = u.id">
                    {{ u.username }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <spacer :y="4"/>

          <!-- お知らせ -->
          <div class="bdr rounded-2" v-if="urlParam !== 'regist'">
            <div class=" bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">
              <div class="fw-bold">お知らせ設定</div>
            </div>
            <!-- 詳細 -->
            <div v-if="urlParam !== 'regist'" class="p-4">
              <div>
                <div>
                  <input
                    type="checkbox"
                    id="sendNotice"
                    :true-value="1"
                    :false-value="0"
                    v-model="sendNotice"
                    @change="regist">
                  <label
                    class="ms-2"
                    for="sendNotice">送信する</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  Loader,
  Calendar,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';
import moment from 'moment';

export default {
  name: 'sales-detail',
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    Calendar,
  },
  data() {
    return {
      flag: {
        invalidUrl: false,
        isLoading: {
          get: true,
        },
      },
      sales: null,
      sendNotice: 1,
      carData: null,
      users: [],
      selectedUserId: 0,
      purchase_date: null,
      keyword: '',
    };
  },
  created() {
    if (this.urlParam === 'regist' && !this.$route.query.salesCar) {
      this.flag.invalidUrl = true;
      this.flag.isLoading.get = false;
    }
    if (this.urlParam === 'regist') {
      const today = moment(new Date());
      this.purchase_date = {
        year: today.year(),
        month: today.month() + 1,
        day: today.date(),
      };
    }
  },
  computed: {
    ...mapState(['user']),
    urlParam() {
      return this.$route.params.id;
    },
    selectedUser() {
      return this.users.find((u) => this.selectedUserId === u.id) || null;
    },
    oilChangeTime() {
      if (!this.sales) return '-';
      const today = moment(new Date());
      const purchaseDate = moment(this.sales.purchase_date);
      const diff = today.diff(purchaseDate, 'months');
      const n = 3; // nヶ月おき
      const remainder = diff % n;
      const oilChange = purchaseDate.add(diff + (n - remainder), 'M').format('YYYY/MM/DD');
      return oilChange;
    },
  },
  watch: {
    user: {
      handler() {
        this.get();
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.get();
      },
      immediate: false,
    },
  },
  methods: {
    /** ローディング開始 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    get() {
      if (this.urlParam === 'regist') {
        this.getSalesCar();
        this.getUsers();
      } else {
        this.getSales();
      }
    },

    // registの場合はsalesCarを取得
    getSalesCar() {
      const params = {
        id: this.$route.query.salesCar,
        flags: [1, 11],
      };

      this.axios({
        method: 'GET',
        url: '/v1/salesCar/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.carData = res.detail;
          if (!this.carData) {
            this.flag.invalidUrl = true;
            this.flag.isLoading.get = false;
          } else if (this.carData.sale) {
            // すでに販売済の場合、詳細ページに遷移
            this.$router.push(`/sales/${this.carData.sale.id}/`);
          } else {
            this.flag.isLoading.get = false;
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.flag.isLoading.get = false;
        });
    },
    getUsers() {
      if (!this.user.role) return;

      const params = {
        role: [1],
        flags: [1],
      };

      if (this.keyword) {
        params.keyword = this.keyword.replace(/　/g, ' ').split(' ');
      }

      if (!this.isSuperUser(this.user)) {
        if (this.user.shops?.[0].id) params.shop_id = this.user.shops[0].id;
        else return;
      }

      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.users = res.list.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },

    // :idの場合はsalesを取得
    getSales() {
      this.axios({
        method: 'GET',
        url: '/v1/sale/get/detail/',
        params: {
          id: this.urlParam,
        },
      })
        .then((response) => {
          const res = response.data;
          this.sales = res.detail;
          this.carData = this.sales?.salesCar;
          this.flag.isLoading.get = false;
          this.selectedUserId = res.detail.user_id;
          const purchaseDate = moment(res.detail.purchase_date);
          this.purchase_date = {
            year: purchaseDate.year(),
            month: purchaseDate.month() + 1,
            day: purchaseDate.date(),
          };
          this.sendNotice = res.detail.send_notice;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });

    },

    regist() {
      this.showLoading();
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      const data = {
        user_id: this.selectedUserId,
        sales_car_id: this.carData.id,
        send_notice: this.sendNotice,
        purchase_date: moment(`${this.purchase_date.year}-${this.purchase_date.month}-${this.purchase_date.day}`).format('YYYY-MM-DD'),
      };

      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        data.id = this.sales.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/sale/${endpoint}/`,
        data,
      })
        .then(() => {
          if (endpoint === 'create') {
            alert('販売情報登録が完了しました。');
          } else {
            alert('販売情報を更新しました。');
          }
          this.hideLoading();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>
