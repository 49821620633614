<template>
  <div :class="$style.wrap">
    <div :class="$style.monthLabel">
      <div :class="$style.arrow" @click="changeMonth(0)"><i class="fa-solid fa-circle-chevron-left"/></div>
      <p :class="$style.label">{{ year }}年{{ month }}月</p>
      <div :class="$style.arrow" @click="changeMonth(1)"><i class="fa-solid fa-circle-chevron-right"/></div>
    </div>
    <ul :class="$style.weekList">
      <li
        v-for="w of numOfWeek + 1"
        :key="w">
        <ul :class="$style.dayList">
          <li v-if="w === 1"
            v-for="n of 7" :key="n">
            <div :class="$style.inner">{{ ['日', '月', '火', '水', '木', '金', '土'][n - 1] }}</div>
          </li>
          <li
            v-if="w !== 1"
            :class="[
              selectedDate.year === year && selectedDate.month === month && selectedDate.day === date(w - 1, d) ? $style.selected : '',
              date(w - 1, d) ? $style.selectable : '',
              roundClass(w, d),
              d === 1 ? $style.sun : d === 7 ? $style.sat : '',
            ]"
            v-for="d of 7"
            :key="d"
            @click="date(w - 1, d) ? selectDate(date(w - 1, d)) : ''">
            {{ roundClass() }}
            <div :class="$style.inner">
              {{ date(w - 1, d) }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'calendar',
  props: {
    pickedDate: {
      type: Object,
    },
    maxWidth: {
      type: Number,
      default: 100,
    },
  },
  emits: (['update:pickedDate']),
  data() {
    return {
      year: Number(moment(new Date()).format('YYYY')),
      month: Number(moment(new Date()).format('MM')),
      selectedDate: {
        year: null,
        month: null,
        day: null,
      },
    };
  },
  created() {
    this.setPickedDate();
  },
  computed: {
    numOfWeek() {
      return Math.ceil((this.dayOfMonth + this.dayOfFirst) / 7);
    },
    dayOfMonth() {
      const fromM = this.month;
      const toM = fromM < 12 ? fromM + 1 : 1;
      const toY = fromM < 12 ? this.year : this.year + 1;
      const fromStr = `${this.year}-${this.zeroPadding(fromM, 2)}-01`;
      const toStr = `${toY}-${toM < 10 ? `0${toM}` : toM}-01`;
      const from = moment(fromStr);
      const to = moment(toStr);
      const diff = to.diff(from, 'days');
      return Number(diff);
    },
    dayOfFirst() {
      return Number(moment(`${this.year}-${this.zeroPadding(this.month, 2)}-01`).format('d'));
    },
    max_width() {
      return `${this.maxWidth}%`;
    },
  },
  watch: {
    pickedDate() {
      this.setPickedDate();
    },
  },
  methods: {
    setPickedDate() {
      if (this.pickedDate) {
        this.selectedDate = this.pickedDate;
        this.year = this.selectedDate.year;
        this.month = this.selectedDate.month;
      }
    },
    zeroPadding(num, len) {
      return (Array(len).join('0') + num).slice(-len);
    },
    date(w, d) {
      const dayNum = (w - 1) * 7 + d - this.dayOfFirst;
      return dayNum > 0 && dayNum <= this.dayOfMonth ? dayNum : '';
    },
    roundClass(w, d) {
      if (w === this.numOfWeek + 1 && d === 1) return this.$style.roundBL;
      if (w === this.numOfWeek + 1 && d === 7) return this.$style.roundBR;
      return '';
    },
    changeMonth(flag) {
      if (flag) { // 次月へ
        if (this.month < 12) this.month += 1;
        else {
          this.month = 1;
          this.year += 1;
        }
      } else if (this.month !== 1) { // 前月へ
        this.month -= 1;
      } else {
        this.month = 12;
        this.year -= 1;
      }
    },
    selectDate(date) {
      this.selectedDate = {
        year: this.year,
        month: this.month,
        day: date,
      };
      this.$emit('update:pickedDate', this.selectedDate);
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  max-width: v-bind(max_width);
}
.monthLabel {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .label {
    margin: 0 12px;
    font-weight: bold;
  }

  .arrow {
    cursor: pointer;
    position: relative;
    transition: all .3s;
    color: #aaaaaa;
    &:hover {
      opacity: .5;
    }
  }
}

.weekList {
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  font-size: 12px;
  li {
    &:not(:first-child) {
      border-top: 1px solid #aaaaaa;
    }
  }
}
.dayList {
  display: flex;
  li {
    transition: all .3s;
    width: calc(100% / 7);
    padding: 2px;
    text-align: center;
    &.roundBL { border-radius: 0 0 0 8px; .inner { border-radius: 0 0 0 7px; } }
    &.roundBR { border-radius: 0 0 8px 0; .inner { border-radius: 0 0 7px 0; } }

    &.sun { color: red; }
    &.sat { color: blue; }

    &:not(:first-child) {
      border-top: none;
      border-left: 1px solid #aaaaaa;
    }

    &.selectable {
      cursor: pointer;
    }
    &:not(.selectable) {
      &:hover {
        background-color: transparent;
      }
    }

    &.selected {
      background-color: #e3f6ff;
      .inner {
        background-color: transparent;
      }
    }

    &.holiday {
      background-color: rgb(255, 230, 234);
      .inner {
        background-color: rgb(255, 230, 234);
      }
    }

    .inner {
      background-color: #ffffff;
      padding: 10px;
      height: 100%;
    }

    &:hover {
      &:not(.selected) {
        background-color: #aaaaaa;
      }
    }

  }
}
</style>
