<template>
  <div class="container">
    <spacer :y="3"/>
    <div class="d-flex align-items-center justify-content-between">
      <div class="fw-bold fs-lg"><i class="fa-regular fa-building me-3"/>ブランド一覧</div>
      <div>
        <router-link
          :to="'/brand/regist'">
          <button
            type="button"
            class="btn btn-primary btn-sm"><i class="fa-regular fa-plus me-2"/>新規登録</button>
        </router-link>
      </div>
    </div>
    <spacer :y="2"/>
    <Loader :flag="flag.isLoading.get">
      <div v-if="!flag.isLoading.get">
        <p v-if="!brands.length">※ブランドが存在しません。新規登録してください。</p>
        <div class="d-flex mt-4" v-else>
          <ul v-if="helper.master.labels">
            <li
              :class="i === 0 ? '' : 'mt-5'"
              v-for="(country, i) in helper.master.labels.countries"
              :key="country.value">
              <div class="mb-3 fw-bold fs-lg d-flex align-items-center">
                <div
                  :class="s.nationalFlag"
                  :style="`background-image: url(/img/nationalFlags/${country.value}.png);`"/>
                <div>{{ country.label }}</div>
              </div>
              <div class="flex-1">
                <ul
                  :class="s.list"
                  v-if="sortedBrands(country.value).length">
                  <router-link
                    v-for="brand of sortedBrands(country.value)"
                    :key="brand.id"
                    :to="`/brand/${brand.id}`">
                    <li class="card overflow-hidden">
                      <div>
                        <div
                          :class="[s.img]"
                          :style="`background-image: url(${brand.images[0]?.url || '/img/noimg.png'});`"/>
                        <div class="p-2 fw-bold fs-xs text-center bdr-t">{{ brand.label }}</div>
                      </div>
                    </li>
                  </router-link>
                </ul>
                <div v-else>ブランドは登録されていません。</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  Spacer,
  Loader,
} from '@/components/parts';

export default {
  name: 'company-detail',
  components: {
    Spacer,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
      },
      brands: [],
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    sortedBrands(country) {
      return this.brands.filter((brand) => brand.country === country);
    },
    getList() {
      this.flag.isLoading.get = true;
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

  },
};
</script>

<style lang="scss" module="s">
.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-bottom: -12px;
  > a {
    width: calc(100% / 8);
    margin-left: 12px;
    margin-bottom: 12px;
  }
}
.img {
  width: 100%;
  padding-top: 75%;
  background-position: center;
  background-size: cover;
  background-color: #e2e2e2;
  position: relative;
}
.nationalFlag {
  width: 36px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  background-position: center;
  background-size: contain;
  margin-right: 12px;
}
</style>
