<template>
  <div>
    <!-- text -->
    <div v-if="type === 'text' || type === 'number'" class="d-flex">
      <input
        @change="changeValue"
        type="text"
        v-model="value"
        :disabled="disabled"
        class="form-control form-control-sm"
        :class="required ? 'bg-red' : ''">
      <span
        v-if="suffix"
        :class="s.suffix"
        class="ms-2 d-flex align-items-center">{{ suffix }}</span>
    </div>

    <!-- textarea -->
    <div v-if="type === 'textarea'">
      <textarea
        @change="changeValue"
        v-model="value"
        :disabled="disabled"
        class="form-control form-control-sm"
        rows="5"
        :class="required ? 'bg-red' : ''"/>
    </div>

    <!-- select -->
    <div v-if="type === 'select'">
      <div :class="s.select_wrap">
        <select
          @change="changeValue"
          :disabled="disabled || !options || !options.length"
          v-model="value"
          class="form-control form-control-sm"
          name="" id=""
          :class="required ? 'bg-red' : ''">
          <option
            :value="null"
            :selected="!value">選択してください</option>
          <option
            v-for="(opt, i) in options"
            :key="i"
            :value="opt.value"
            :selected="opt.value === value">{{ opt.label }}</option>
        </select>
      </div>
    </div>
    
    <div v-if="type === 'customedSelect'">
      <SelectForm
        :name="name"
        :options="options"
        :disabled="disabled || !options || !options.length"
        :required="required"
        :initValue="initValue"
        @changeValue="changeValue" />
    </div>

    <!-- checkbox -->
    <div
      v-if="type === 'checkbox'"
      class="d-flex align-items-center">
      <input
        type="checkbox"
        @change="changeValue"
        :checked="value === 1"
        :disabled="disabled"
        class="me-2"
        :name="name"
        :id="name">
      <label
        class="fs-sm"
        :for="name"
        >{{ label }}</label>
    </div>

    <!-- radio -->
    <ul
      v-if="type === 'radio'"
      class="d-flex align-items-center">
      <li
        v-for="option in options"
        :key="option.value">
        <input
          type="radio"
          @change="changeValue"
          :checked="value === option.value"
          :disabled="disabled"
          class="me-2"
          :value="option.value"
          :name="option.name"
          :id="option.name">
        <label
          class="fs-sm me-4"
          :for="option.name"
          >{{ option.label }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
import SelectForm from './SelectForm.vue';

export default {
  name: 'form-parts',
  props: ['type', 'options', 'label', 'name', 'initValue', 'disabled', 'required', 'suffix'],
  components: {
    SelectForm,
  },
  data() {
    return {
      value: null,
    };
  },
  created() {
    this.value = this.initValue;
  },
  watch: {
    initValue() {
      this.value = this.initValue;
    },
  },
  methods: {
    changeValue(e) {
      let value = this.value;
      if (this.type === 'checkbox') value = e.target.checked ? 1 : 0;
      if (this.type === 'radio') value = Number(e.target.value);
      if (this.type === 'customedSelect') value = e;
      // SalesCar/Detail.vueにおいてflag以外はLoaderコンポーネント内となるため１階層深くなっている
      if (this.name === 'flag') this.$parent.changeValue(this.name, value);
      else {
        try {
          this.$parent.$parent.changeValue(this.name, value);
        } catch {
          this.$parent.changeValue(this.name, value);
        }
      }
    },
  },
};
</script>
<style lang="scss" module="s">
.select_wrap {
  position: relative;
  select {
    padding-right: 24px;
  }
  &::after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(calc(-50% - 2px)) rotate(45deg);
    width: 6px;
    height: 6px;
    border: 1px solid;
    border-color: transparent var(--label-secondary) var(--label-secondary) transparent ;
  }
}
.suffix {
  white-space: nowrap;
  font-size: 11px;
}
</style>
