<template>
  <div class="container-md">
    <spacer :y="4"/>
    <div class="fw-bold fs-lg"><i class="fa-regular fa-clipboard me-2"/>買取車両情報{{ urlParam === 'regist' ? '登録' : '詳細'}}</div>
    <spacer :y="2"/>

    <Loader :flag="flag.isLoading.get">
      <!-- 基本情報 -->
      <div class="bdr rounded-2" v-if="!flag.isLoading.get">
        <div class="d-flex align-items-center justify-content-between bg-gray-secondary bdr-b rounded-top-2 px-4 py-3">

          <div class="fw-bold">基本情報</div>
          <div class="d-flex">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="!readySubmit"
              @click="regist(1)"><i class="fa-regular fa-download me-2"/>掲載する</button>
            <button
              type="button"
              class="btn btn-outline-secondary ms-2 btn-sm"
              :disabled="!readySubmit"
              @click="regist(2)"><i class="fa-regular fa-floppy-disk me-2"/>下書き保存</button>
            <button
              v-if="[1].includes(purchaseCar?.flag)"
              type="button"
              class="btn btn-outline-danger ms-2 btn-sm"
              :disabled="!readySubmit"
              @click="regist(901)"><i class="fa-regular fa-floppy-disk me-2"/>掲載中止</button>
          </div>
        </div>
        <!-- edit -->
        <div class="px-4 py-3 w-100">
          <div class="d-flex fs-xs fc-red justify-content-end">「*」は必須項目です。</div>
          <div class="d-flex align-items-start bdr w-100" v-if="!flag.isLoading.get">
            <ul
              v-for="(value, key) in formItems"
              :key="key"
              :class="s.forms">
              <li
                v-for="(item, i) in value"
                :key="i"
                class="d-flex">
                <div class="fs-xs bg-gray w-150px d-flex align-items-center flex-shrink-0">{{ item.label }}<span v-if="item.required" class="fc-red">*</span></div>
                <div :class="item.width === 'full' ? 'flex-1' : ''">

                  <!-- グレード -->
                  <div
                    v-if="item.name === 'grade'">
                    <FormParts
                      :type="'text'"
                      :name="'grade'"
                      :initValue="values.grade"/>
                    <spacer :y="0.5"/>
                    <div class="fs-xs">付加情報</div>
                    <FormParts
                      :type="'textarea'"
                      :name="'grade_comment'"
                      :initValue="values.grade_comment"/>
                  </div>

                  <!-- カラー -->
                  <div
                    v-if="item.name === 'color'">
                    <div class="d-flex">
                      <FormParts
                        class="flex-1"
                        :type="'select'"
                        :name="'color_type'"
                        :initValue="values.color_type"
                        :required="!values.color_type"
                        :options="options('color_type')"/>
                      <spacer :x="0.5"/>
                      <FormParts
                        class="flex-1"
                        :type="'select'"
                        :name="'color_detail'"
                        :initValue="values.color_detail"
                        :disabled="!values.color_type"
                        :required="values.color_type && !values.color_detail"
                        :options="options('color_detail')"/>
                    </div>
                    <spacer :y="0.5"/>
                    <FormParts
                      :type="'text'"
                      :name="'color_name'"
                      :initValue="values.color_name"/>
                  </div>

                  <!-- 価格 -->
                  <div
                    v-if="item.name === 'price'">
                    <div>
                      <div>
                        <div class="fs-xs fc-gray">相場上限</div>
                        <FormParts
                          :type="'number'"
                          :name="'market_price_max'"
                          :initValue="values.market_price_max"
                          :suffix="'万円'" />
                      </div>
                      <spacer :x="0.5"/>
                      <div>
                        <div class="fs-xs fc-gray">相場下限</div>
                        <FormParts
                          :type="'number'"
                          :name="'market_price_min'"
                          :initValue="values.market_price_min"
                          :suffix="'万円'" />
                      </div>
                    </div>
                  </div>

                  <!-- そのほか -->
                  <div
                    v-else>
                    <FormParts
                      :type="item.type"
                      :options="options(item.name)"
                      :label="item.checkLabel"
                      :name="item.name"
                      :required="item.required && !values[item.name]"
                      :initValue="values[item.name]"
                      :suffix="item.suffix" />
                    <div v-if="['brand_id', 'vehicle_id'].includes(item.name)">
                      <div
                        type="button"
                        class="fs-xs text-decoration-underline mt-2 line-height-1 fc-blue"
                        @click="openRegistModal(item.name)">{{ item.label }}新規登録</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <spacer :y="1"/>
          <!-- オプション -->
          <!-- <div v-if="!flag.isLoading.get">
            <div class="fw-bold fs-lg"><i class="fa-regular fa-gear me-3"/>オプション</div>
            <spacer :y="2"/>
            <OptionForm
              :initValue="values.options"/>
          </div> -->
          <div :class="s.imageWrap">
            <div class="mt-3 mb-2">画像</div>
            <ImageForm
              v-model:initMedias="images"
              :count="5"
              @changeFlag="changeImageFlag"
              @changeData="updateImages" />
          </div>
          <spacer :y="3"/>
          <div v-if="urlParam !== 'regist'">
            <spacer :y="2"/>
            <div>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                @click="regist(999)"><i class="fa-regular fa-trash-can me-1"/>削除する</button>
            </div>
          </div>
        </div>
      </div>
    </Loader>
    <spacer :y="4"/>
  </div>
</template>

<script>
import {
  Spacer,
  Loader,
  // OptionForm,
  FormParts,
  ImageForm,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions';
import { mapState } from 'vuex';

export default {
  mixins: [cf],
  components: {
    Spacer,
    Loader,
    // OptionForm,
    FormParts,
    ImageForm,
  },
  data() {
    return {
      flag: {
        isLoading: {
          get: true,
        },
        isFirst: true,
        beforeUploadImages: false,
      },
      purchaseCar: null,
      brands: [],
      vehicles: [],
      images: [],
      formItems: {
        left: [
          {
            name: 'brand_id',
            label: 'ブランド',
            type: 'customedSelect',
            required: true,
          },
          {
            name: 'vehicle_id',
            label: '車種',
            type: 'customedSelect',
            required: true,
          },
          {
            name: 'regist_year',
            label: '年式（初度登録）',
            type: 'select',
            required: false,
          },
          {
            name: 'mileage',
            label: '走行距離目安',
            type: 'number',
            required: false,
            suffix: 'km',
          },
          // {
          //   name: 'repair_history',
          //   label: '修復歴',
          //   type: 'checkbox',
          //   checkLabel: 'あり',
          // },
          {
            name: 'color',
            label: 'カラー',
            width: 'full',
            required: true,
          },
          {
            name: 'price',
            label: '買取相場',
            required: false,
          },
        ],
        right: [
          {
            name: 'grade',
            label: 'グレード',
            width: 'full',
          },
          {
            name: 'summary',
            label: '概要',
            type: 'textarea',
            width: 'full',
            required: false,
          },
          {
            name: 'comment',
            label: 'コメント',
            type: 'textarea',
            width: 'full',
            required: false,
          },
        ],
      },
      values: {
        brand_id: null,
        vehicle_id: null,
        summary: null,
        comment: null,
        market_price_max: 0,
        market_price_min: 0,
        regist_year: null,
        mileage: null,
        grade: null,
        grade_comment: null,
        color_type: null,
        color_detail: null,
        color_name: null,
        repair_history: 0,
        options: null,
      },
    };
  },
  created() {
    this.getPurchaseCar();
    this.getBrands();
  },
  computed: {
    ...mapState(['helper', 'modal']),
    urlParam() {
      return Number(this.$route.params.id) || 'regist';
    },
    readySubmit() {
      const flag = {
        normal: false,
        color: false,
      };

      // everyでの判定から除外
      const excludeItemName = ['color'];
      const allItems = [...this.formItems.left, ...this.formItems.right];
      const requiredItems = allItems.filter((item) => item.required && !excludeItemName.includes(item.name));
      flag.normal = requiredItems.every((item) => this.isValidValue(item.name));

      // 車体色（color）
      flag.color = Boolean(this.values.color_type && this.values.color_detail);

      // 全てtrueになっているか確認
      return !this.flag.beforeUploadImages && Object.keys(flag).every((key) => flag[key]);
    },
    selectedBrand() {
      return this.values.brand_id;
    },
  },
  mounted() {
    // storeを監視してカテゴリ編集後getする
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'modal/contents/parentUpdate') {
        if (this.modal.contents.updateData.get === 'brand') {
          this.getBrands();
          this.values.brand_id = this.modal.contents.updateData.id;
        } else if (this.modal.contents.updateData.get === 'vehicle') {
          this.getVehicles();
          this.values.vehicle_id = this.modal.contents.updateData.id;
        }
      }
    });
  },
  watch: {
    selectedBrand() {
      this.getVehicles();
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング終了 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    isValidValue(name) {
      const allTypes = {
        number: ['flag', 'brand_id', 'vehicle_id', 'regist_year', 'mileage', 'inspection_month', 'repair_history', 'market_price_max', 'market_price_min'],
        string: ['summary', 'comment', 'grade', 'grade_comment', 'color_type', 'color_detail', 'color_name', 'options'],
      };
      const type = Object.keys(allTypes).find((key) => allTypes[key].includes(name));
      let validType = false;
      if (type === 'number' && (this.values[name] || this.values === 0)) validType = !isNaN(this.values[name]);
      else if (type === 'string' && this.values[name]) validType = typeof this.values[name] === 'string';
      return validType;
    },
    carLabelObj(key) {
      if (!this.helper.master?.labels?.cars) return null;
      return key ? this.helper.master.labels.cars[key] : this.helper.master.labels.cars;
    },
    obj2Array(obj) {
      return obj ? Object.keys(obj).map((key) => Object({ label: obj[key], value: Number(key) || Number(key) === 0 ? Number(key) : key })) : null;
    },

    formatYears(type) {
      const arr = [];
      const jpYears = [
        { label: '昭和', start: 1926, end: 1988 },
        { label: '平成', start: 1989, end: 2018 },
        { label: '令和', start: 2019, end: 9999 },
      ];
      const nowYear = new Date().getFullYear();
      const n = type === 'before' ? nowYear - 1926 : 5;
      for (let i = 0; i < n; i += 1) {
        const year = type === 'before' ? nowYear - i : nowYear + i;
        const yearJP = jpYears.find((obj) => obj.start <= year && obj.end >= year);
        arr.push({ label: `${year}（${yearJP.label}${year - yearJP.start + 1}）`, value: year });
      }
      return arr;
    },
    options(name) {
      if (name === 'regist_year') return this.formatYears('before');
      if (name === 'brand_id') return this.brands.map((obj) => Object({ label: obj.label, value: obj.id, country: obj.country }));
      if (name === 'vehicle_id') return this.vehicles.map((obj) => Object({ label: obj.name, value: obj.id }));
      // カラー関連
      const colors = this.carLabelObj('colors') || [];
      if (name === 'color_type') return Object.keys(colors).map((key) => Object({ label: colors[key].label, value: key }));
      if (name === 'color_detail') return this.values.color_type && colors[this.values.color_type] ? this.obj2Array(colors[this.values.color_type].details) : [];
      // そのほか
      return this.obj2Array(this.carLabelObj(name));
    },
    changeValue(name, value) {
      this.values[name] = value;
      // color_typeが変更になった場合はcolor_detailをnullにする
      if (name === 'color_type') this.values.color_detail = null;
    },
    setValues() {
      Object.keys(this.values).forEach((key) => {
        this.values[key] = this.purchaseCar[key] || null;
      });
      this.images = this.purchaseCar.images || [];
    },
    updateImages(images) {
      this.images = images;
    },
    changeImageFlag(value) {
      this.flag.beforeUploadImages = value;
    },
    getPurchaseCar() {
      if (this.urlParam === 'regist') {
        this.flag.isLoading.get = false;
        this.flag.isFirst = false;
        return;
      }
      this.flag.isLoading.get = true;
      const params = {
        flags: [1, 2, 901],
        id: this.urlParam,
      };

      this.axios({
        method: 'GET',
        url: '/v1/purchaseCar/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.purchaseCar = res.detail;
          if (!this.purchaseCar) {
            alert('買取車両が存在しませんでした。');
            this.$router.push('/purchase-car');
          } else {
            this.setValues();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.flag.isLoading.get = false;
        });
    },

    regist(flag) {
      if (flag === 999 && !confirm('買取車両情報を削除してよろしいですか？')) return;
      if (flag === 901 && !confirm('車両車両情報の掲載を中止してよろしいですか？')) return;
      this.showLoading();
      const endpoint = this.urlParam === 'regist' ? 'create' : 'update';
      let data;

      const imageIds = this.images.map((image) => image.id);
      if ([999, 901].includes(flag)) {
        data = { flag };
      } else {
        data = {
          ...this.values,
          images: imageIds,
          flag,
        };
      }
      // 更新時のみのプロパティ
      if (endpoint === 'update') {
        data.id = this.purchaseCar.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/purchaseCar/${endpoint}/`,
        data,
      })
        .then(() => {
          if (endpoint === 'create') {
            alert('買取車両情報を登録しました。');
          } else {
            alert('買取車両情報を更新しました。');
          }
          this.$router.replace('/purchase-car/');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getBrands() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/brand/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.brands = res.brands.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    getVehicles() {
      if (!this.selectedBrand) {
        this.vehicles = [];
        return;
      }
      if (!this.flag.isFirst) this.values.vehicle_id = null;

      const params = {
        brand_id: this.selectedBrand,
      };

      this.axios({
        method: 'GET',
        url: '/v1/vehicle/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.vehicles = res.vehicles.data;
          if (!this.vehicles?.length && confirm('該当ブランドの車種が登録されていませんでした。\n車種登録モーダルを開いてよろしいですか?')) {
            this.openRegistModal('vehicle_id');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
    openRegistModal(name) {
      const data = {
        name: name.replace('_id', ''),
        brand_id: name === 'vehicle_id' ? this.selectedBrand : null,
      };
      const args = {
        modalName: 'RegistBrandVehicle',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module="s">
$bdr-color: var(--border-gray-opacity-1);
.forms {
  width: 50%;
  border-bottom: 1px solid $bdr-color;
  margin-bottom: -1px;
  &:first-child {
    border-right: 1px solid $bdr-color;
  }
  > li {
    > * {
      padding: 6px 12px;
    }
    &:not(:first-child) {
      border-top: 1px solid $bdr-color;
    }
  }
}
.vehicles {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
  > li {
    width: calc(100% / 6);
    padding: 6px;
    > * {
      display: block;
      color: var(--label-primary);
      > :first-child {
        padding-top: 75%;
        width: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
.imageWrap {
  max-width: 100%;
}
</style>
